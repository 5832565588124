import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        style: { borderRadius: 20, maxWidth: '550px', width: '90%' },
      }}
    >
      <Box className="flex flex-col justify-center items-center p-4 gap-4">
        <DialogTitle id="confirmation-dialog-title" sx={{ textAlign: 'center', padding: '8px 16px' }}>
          매칭 선택을 확정하시겠습니까?
        </DialogTitle>

        {/* 🙏 이모지 추가 부분 */}
        <Typography sx={{ fontSize: '50px', textAlign: 'center' }}>🙏</Typography>

        <Typography
          id="confirmation-dialog-description"
          sx={{ padding: '0 16px', textAlign: 'center', wordWrap: 'break-word' }}
        >
          매칭 선택을 확정하시면, 멘티와의 소중한 인연을 시작하게 됩니다. 만약 도중에 수업을 중단하실 경우,
          해당 멘티는 이번 기수에서 다른 멘토를 만날 기회가 줄어들 수 있으니, 책임감 있는 선택을 부탁드립니다.
        </Typography>

        <DialogActions className="flex w-full justify-center" sx={{ padding: '8px 16px', display: 'flex', justifyContent: 'center' }}>
          {/* 취소 버튼 */}
          <Box className="flex flex-1 justify-center items-center" sx={{ maxWidth: '150px', margin: '0 8px' }}>
            <Button
              variant="text"
              onClick={onClose}
              sx={{
                width: '100%',
                height: '56px', // 동일한 높이 설정
                borderRadius: '12px',
                color: 'black',
                minWidth: '100px', // 최소 너비 설정
              }}
            >
              취소
            </Button>
          </Box>

          {/* 계속하기 버튼 */}
          <Box className="flex flex-1 justify-center items-center" sx={{ maxWidth: '150px', margin: '0 8px' }}>
            <Button
              variant="text"
              onClick={onConfirm}
              autoFocus
              sx={{
                width: '100%',
                height: '56px', // 동일한 높이 설정
                borderRadius: '12px',
                color: 'white',
                backgroundColor: '#E4782A',
                '&:hover': {
                  backgroundColor: '#D4671B',
                },
                minWidth: '100px', // 최소 너비 설정
              }}
            >
              계속하기
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;

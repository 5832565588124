import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, query, where, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from 'firebase_config';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

// Fetch all matcheds
export const fetchMatcheds = createAsyncThunk('matched/fetchMatcheds', async () => {
  const fetchRef = collection(db, 'Matcheds');
  const querySnapshot = await getDocs(fetchRef);
  const matcheds = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      matcheds.push({
        ...doc.data(),
        docId: doc.id,
      });
    }
  });
  return matcheds;
});

// Fetch a specific matched by mentor UID
export const fetchMatched = createAsyncThunk('matched/fetchMatched', async (uid) => {
  const fetchRef = collection(db, 'Matcheds');
  const queryRef = query(
    fetchRef,
    where('mentorUid', '==', uid),
    where('state', '==', 1),
    where('generation', '==', GENERATION)
  );
  const querySnapshot = await getDocs(queryRef);
  let matchedData = {};
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      matchedData = {
        ...doc.data(),
        docId: doc.id,
      };
    }
  });
  return matchedData;
});

// Create a new matched
export const createMatched = createAsyncThunk('matched/createMatched', async (newData) => {
  const fetchRef = collection(db, 'Matcheds');
  const docRef = await addDoc(fetchRef, newData);
  return { ...newData, docId: docRef.id };
});

// Update an existing matched
export const updateMatched = createAsyncThunk('matched/updateMatched', async ({ docId, updatedData }) => {
  const docRef = doc(db, 'Matcheds', docId);
  await updateDoc(docRef, updatedData);
  return { ...updatedData, docId };
});

// Delete a matched
export const deleteMatched = createAsyncThunk('matched/deleteMatched', async (docId) => {
  const docRef = doc(db, 'Matcheds', docId);
  await deleteDoc(docRef);
  return docId;
});

const matchedSlice = createSlice({
  name: 'matched',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMatcheds.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchMatched.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(createMatched.fulfilled, (state, action) => {
        if (state.data) {
          state.data.push(action.payload);
        } else {
          state.data = [action.payload];
        }
      })
      .addCase(updateMatched.fulfilled, (state, action) => {
        if (state.data) {
          const index = state.data.findIndex((item) => item.docId === action.payload.docId);
          if (index !== -1) {
            state.data[index] = action.payload;
          }
        }
      })
      .addCase(deleteMatched.fulfilled, (state, action) => {
        if (state.data) {
          state.data = state.data.filter((item) => item.docId !== action.payload);
        }
      })
      .addCase(fetchMatcheds.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchMatched.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(createMatched.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(updateMatched.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(deleteMatched.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const selectMatched = (state) => state.matched.data;

export default matchedSlice.reducer;

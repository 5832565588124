import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllMatchings, selectMatching } from 'store/matchingSlice';
import { createMatched } from 'store/matchedSlice';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from 'firebase_config';

const mentorStyle = {
  backgroundColor: 'lightSkyBlue',
};

const menteeMatchedStyle = {
  backgroundColor: 'darkgray',
};

const TABLE_COLUMN_MIN_WIDTH = 100

const MatchingFormContent = () => {
  const dispatch = useDispatch();
  const matchings = useSelector(selectMatching);
  const [filteredMatchings, setFilteredMatchings] = useState([]);
  const [applicationsData, setApplicationsData] = useState({});
  const [selections, setSelections] = useState({});

  useEffect(() => {
    // Fetch matchings and filter based on mentor's tmpMatchedStatus
    const fetchData = async () => {
      const matchingsArray = matchings || []; // matchings가 null 또는 undefined인 경우 빈 배열로 처리
      const filtered = [];
      for (const matching of matchingsArray) {
        const mentorQuery = query(
          collection(db, 'Applications'),
          where('aid', '==', matching.mentorAid)
        );
        const mentorSnapshot = await getDocs(mentorQuery);
        let mentorStatusValid = true;

        mentorSnapshot.forEach((doc) => {
          if (doc.exists() && doc.data().tmpMatchedStatus === 1) {
            mentorStatusValid = false;
          }
        });

        // If the mentor's tmpMatchedStatus is not 1, add the matching to filtered list
        if (mentorStatusValid) {
          filtered.push(matching);
        }
      }
      setFilteredMatchings(filtered);
    };

    if (matchings) {
      fetchData();
    }
  }, [matchings]);


  // Total Matchings count
  const totalMatchingsCount = filteredMatchings.length;

  const fetchApplicationsData = async (matching) => {
    const applications = [];

    // Fetch mentee applications
    for (const menteeAid of matching.menteeAidList || []) {
      const menteeQuery = query(collection(db, 'Applications'), where('aid', '==', menteeAid));
      const menteeSnapshot = await getDocs(menteeQuery);
      menteeSnapshot.forEach((doc) => {
        if (doc.exists()) {
          applications.push({ ...doc.data(), docId: doc.id, type: 'mentee' });
        }
      });
    }

    // Fetch mentor application
    const mentorQuery = query(
      collection(db, 'Applications'),
      where('aid', '==', matching.mentorAid)
    );
    const mentorSnapshot = await getDocs(mentorQuery);
    mentorSnapshot.forEach((doc) => {
      if (doc.exists() && doc.data().tmpMatchedStatus !== 1) {
        applications.push({ ...doc.data(), docId: doc.id, type: 'mentor' });
      }
    });

    // Sort applications to place the mentor at the top
    applications.sort((a, b) => (a.type === 'mentor' ? -1 : 1));

    setApplicationsData((prev) => ({
      ...prev,
      [matching.docId]: applications,
    }));

    setSelections((prev) => ({
      ...prev,
      [matching.docId]: {
        mentor: applications.find((app) => app.type === 'mentor') || null,
        mentees: [],
      },
    }));
  };

  const handleSelectMentor = (matchingId, application) => {
    if (application.type === 'mentor' && application.tmpMatchedStatus !== 1) {
      setSelections((prev) => ({
        ...prev,
        [matchingId]: {
          ...prev[matchingId],
          mentor: application,
        },
      }));
    }
  };

  const handleSelectMentee = (matchingId, application) => {
    if (application.type === 'mentee') {
      setSelections((prev) => {
        const currentMentees = prev[matchingId]?.mentees || [];
        const updatedMentees = currentMentees.includes(application)
          ? currentMentees.filter((mentee) => mentee.docId !== application.docId)
          : [...currentMentees, application];

        return {
          ...prev,
          [matchingId]: {
            ...prev[matchingId],
            mentees: updatedMentees,
          },
        };
      });
    }
  };

  const isMenteeDisabled = (matchingId, mentee, matchingSubject) => {
    const selectedMentees = selections[matchingId]?.mentees || [];

    const menteeIsMatched = isMenteeMatched(mentee, matchingSubject);

    return selectedMentees.length > 0 && !selectedMentees.includes(mentee) || menteeIsMatched;
  };

  const updateApplications = async (matching, matchingId) => {
    const { mentor, mentees } = selections[matchingId];

    try {
      // Update mentor's application status
      if (mentor) {
        await updateDoc(doc(db, 'Applications', mentor.docId), {
          tmpMatchedStatus: mentor.tmpMatchedStatus + 1,
          tmpMatchedSubjects: [...mentor.tmpMatchedSubjects, matching.subject],
        });
      }

      // Update mentees' application statuses
      for (const mentee of mentees) {
        await updateDoc(doc(db, 'Applications', mentee.docId), {
          tmpMatchedStatus: mentee.tmpMatchedStatus + 1,
          tmpMatchedSubjects: [...mentee.tmpMatchedSubjects, matching.subject],
        });
      }

      // Create new matched document using Redux action
      if (mentor && mentees.length > 0) {
        const matchedData = {
          mid: matchingId,
          mentorAid: mentor.aid,
          menteeAid: mentees[0].aid,
          subject: matching.subject,
          generation: matching.generation,
        };

        await dispatch(createMatched(matchedData)).unwrap();
      }

      alert('Matching successful!');

      setFilteredMatchings((prev) => prev.filter((m) => m.docId !== matchingId));

      setSelections((prev) => ({
        ...prev,
        [matchingId]: { mentor: null, mentees: [] },
      }));

    } catch (error) {
      console.error('Error updating applications or creating matched:', error);
      alert('An error occurred while updating the applications or creating the matched.');
    }
  };

  const handleMatch = (matching) => {
    const matchingId = matching.docId;
    const { mentor, mentees } = selections[matchingId] || {};

    if (mentor && mentees.length > 0) {
      updateApplications(matching, matchingId);
    } else {
      alert('Please select a mentor and at least one mentee.');
    }
  };

  const isMenteeMatched = (mentee, matchingSubject) => {
    return mentee.tmpMatchedSubjects.includes(matchingSubject);
  };

  return (
    <Box>
      <Typography variant="h6" component="h2" gutterBottom>
        Matchings and Applications Data
      </Typography>

      {/* Display the total number of matchings */}
      <Box mb={2}>
        <Typography variant="body1">
          아직 매칭되지 않은 항목 수: {totalMatchingsCount}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>
                  매칭 참고자료
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMatchings.map((matching) => (
              <React.Fragment key={matching.docId}>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Accordion
                      onChange={(event, isExpanded) =>
                        isExpanded && fetchApplicationsData(matching)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-content-${matching.docId}`}
                        id={`panel-header-${matching.docId}`}
                      >
                        <Typography>
                          {matching.mid} - Mentor: {matching.mentorName} - Subject: {matching.subject} - Generation: {matching.generation}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>Select</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>AID</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>유저타입</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>이메일</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>이름</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH * 2}}>수업가능 과목</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>1순위 과목</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>2순위 과목</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>기타 과목</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>기수</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH * 2 }}>수업 희망 연령대</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>한국어 수준</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>주언어</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>매칭상태</TableCell>
                              <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>매칭된 과목</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {applicationsData[matching.docId]?.map((application) => (
                              <TableRow
                                key={application.docId}
                                style={
                                  application.type === 'mentor'
                                    ? mentorStyle
                                    : isMenteeMatched(application, matching.subject)
                                      ? menteeMatchedStyle
                                      : {}
                                }
                              >
                                <TableCell>
                                  <Checkbox
                                    checked={
                                      application.type === 'mentor'
                                        ? selections[matching.docId]?.mentor?.docId === application.docId
                                        : selections[matching.docId]?.mentees.some(
                                          (mentee) => mentee.docId === application.docId
                                        )
                                    }
                                    onChange={() =>
                                      application.type === 'mentor'
                                        ? handleSelectMentor(matching.docId, application)
                                        : handleSelectMentee(matching.docId, application)
                                    }
                                    disabled={
                                      (application.type === 'mentor' && application.tmpMatchedStatus === 1) ||
                                      (application.type === 'mentee' &&
                                        isMenteeDisabled(matching.docId, application, matching.subject))
                                    }
                                  />
                                </TableCell>
                                <TableCell>{application.aid}</TableCell>
                                <TableCell>
                                  {application.userType === 'mentor'
                                    ? '멘토'
                                    : application.userType === 'mentee'
                                      ? '멘티'
                                      : '없는 타입'}
                                </TableCell>
                                <TableCell>{application.email}</TableCell>
                                <TableCell>{application.name}</TableCell>
                                {application.userType === "mentor" && application.availableSubjects.length > 0
                                  ? application.availableSubjects.join(', ')
                                  : '-'}
                                <TableCell>{application.prioritySubjects?.first ?? "-"}</TableCell>
                                <TableCell>{application.prioritySubjects?.second ?? "-"}</TableCell>
                                <TableCell>{application.extraSubjects ?? "-"}</TableCell>
                                <TableCell>{application.generation}기</TableCell>
                                <TableCell>
                                  {application.availableCourses.length > 0
                                    ? application.availableCourses.join(', ')
                                    : '수업가능한 코스 X'}
                                </TableCell>
                                <TableCell>{application.koreanProficiency}</TableCell>
                                <TableCell>{application.localLanguage}</TableCell>
                                <TableCell>{application.tmpMatchedStatus}</TableCell>
                                <TableCell>
                                  {application.tmpMatchedSubjects.length === 0
                                    ? '매칭 X'
                                    : application.tmpMatchedSubjects.join(', ')}
                                </TableCell>
                              </TableRow>
                            ))}
                            {applicationsData[matching.docId]?.length === 0 && (
                              <TableRow>
                                <TableCell colSpan={8} align="center">
                                  No applications found.
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        <Box display="flex" justifyContent="flex-start" mt={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleMatch(matching)}
                            disabled={
                              !selections[matching.docId]?.mentor ||
                              selections[matching.docId]?.mentees.length === 0
                            }
                          >
                            Match
                          </Button>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MatchingFormContent;

import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import user from 'store/userSlice';
import menus from 'store/menuSlice';
import messages from 'store/messageSlice';
import application from 'store/applicationSlice';
import qna from 'store/qnaSlice';
import matching from 'store/matchingSlice';
import planning from 'store/planningSlice';
import lessonLog from 'store/lessonLogSlice';
import matched from 'store/matchedSlice';

const store = configureStore({
  reducer: {
    user,
    menus,
    messages,
    application,
    qna,
    matching,
    planning,
    lessonLog,
    matched,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }).concat(logger),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_FB_API_KEY === 'development',
});

export default store;

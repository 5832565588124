// MatchedAdminPage.jsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';
import MatchedList from './MatchedList';
import MatchingFormContent from './MatchingFormContent';
import ApplicationList from './ApplicationList'; // New Component
import { useDispatch, useSelector } from 'react-redux';
import { fetchMatcheds, selectMatched } from 'store/matchedSlice';
import { fetchAllMatchings } from 'store/matchingSlice';
import {
  fetchUnmatchedApplications,
  fetchUnSelectedApplications,
  selectUnmatchedApplications,
  selectUnselectedApplications,
} from 'store/applicationSlice'; // Import for unmatched applications

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const MatchedAdminPage = () => {
  const dispatch = useDispatch();
  const matcheds = useSelector(selectMatched);
  const unselectedApplications = useSelector(selectUnselectedApplications); // Use selector for unselected applications
  const [value, setValue] = useState(0);

  useEffect(() => {
    dispatch(fetchMatcheds());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      dispatch(fetchAllMatchings());
    } else if (newValue === 2) {
      dispatch(fetchUnSelectedApplications()); // Fetch unmatched applications when this tab is selected
    }
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Matcheds Admin
        </Typography>
        <Tabs value={value} onChange={handleTabChange} aria-label="admin page tabs">
          <Tab label="Matched List" />
          <Tab label="Create New Matched" />
          <Tab label="선택받지 못한 멘티들" />
        </Tabs>
        <TabPanel value={value} index={0}>
          {matcheds ? <MatchedList data={matcheds} /> : <Typography>Loading...</Typography>}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MatchingFormContent />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {unselectedApplications ? (
            <ApplicationList data={unselectedApplications} />
          ) : (
            <Typography>Loading...</Typography>
          )}
        </TabPanel>
      </Box>
    </Container>
  );
};

export default MatchedAdminPage;

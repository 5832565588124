import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { orderBy, serverTimestamp, where } from 'firebase/firestore';
import applicationConverter from 'models/application_model';
import matchingConverter from 'models/matching_model';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FirestorePath from 'services/firebase_path';
import { addDocument, getCollectionFuture, setDocument } from 'services/firebase_services';
import { selectUser, selectedUserApplication } from 'store/userSlice';

const SelectMenteeTest = (props) => {
  const user = useSelector(selectUser);
  const userApplication = useSelector(selectedUserApplication);

  const [selectedMenteeList, setSelectedMenteeList] = useState([]);
  const [menteeList, setMenteeList] = useState([]);
  const { subject } = props;

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      // matching 가져와서 업데이트해주기
      async function getMenteeList() {
        const docs = await getCollectionFuture(
          FirestorePath.applications(),
          (data, docId) => applicationConverter.fromFirestore(data),
          [where('availableSubjects', 'array-contains', '과학'), where('userType', '==', 'mentee')]
        );

        const temp = docs.filter(
          (doc) => doc.email === 'bhgt9510@naver.com'
          // &&
          // doc.email !== 'bhgt4563@naver.com' &&
          // doc.email !== 'cwcw0908@gmail.com'
        );
        // console.log(temp);
        // console.log(temp.length);

        setMenteeList(temp);
        setSelectedMenteeList(temp);
      }

      getMenteeList();
    }
  }, [user, setMenteeList]);

  const saveMatchingData = async (e) => {
    // 매칭하기 버튼 클릭 시 동작할 함수
    const menteeList = selectedMenteeList;
    const menteeUidList = Array.from(new Set([menteeList[0].uid, menteeList[1].uid]));
    const menteeAidList = [menteeList[0].aid, menteeList[1].aid];

    const array = [
      [menteeList[0].aid, applicationConverter.toFirestore(menteeList[0])],
      [menteeList[1].aid, applicationConverter.toFirestore(menteeList[1])],
    ];
    const menteeMap = Object.fromEntries(array);

    const subject = '과학';
    const { uid, email } = user;
    const {
      aid,
      contact,
      name,
      gender,
      birth,
      availableCourses,
      availableSubjects,
      availableDateTime,
      extraSubjects,
    } = userApplication;

    // 1. matching doc 생성
    const object = {
      subject: subject, // 과목 이름
      state: 0, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류
      appliedAt: serverTimestamp(), // 매칭신청 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
      approvedAt: null, // 매칭승인 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
      approvedMentee: null, // 승인된 멘티 application id
      mentorUid: uid, // 멘토 user id
      mentorAid: aid, // 멘토 application id
      mentorEmail: email, // 멘토 이메일
      mentorContact: contact, // 멘토 연락처(전화번호)
      mentorName: name, // 멘토 이름
      mentorGender: gender, // 멘토 성별
      mentorBirth: birth, // 멘토 생일(나이)
      mentorCourses: availableCourses, // 멘토 수업 과정ex) ['유초등저학년(1~2학년)', '중등', '고등']
      mentorSubjects: availableSubjects, // 멘토 수업 가능 과목
      mentorExtraSubjects: extraSubjects, // 멘토 기타 과목
      mentorDateTime: availableDateTime, // 멘토 수업 가능 시간대
      menteeUidList: menteeUidList, // 멘티들 user id 리스트(쿼리를 위한)
      menteeAidList: menteeAidList, // 멘티들 application id 리스트(쿼리를 위한)
      menteeList: menteeMap, // 멘티 Application Object 리스트
    };

    const docId = await addDocument(
      FirestorePath.matchings(),
      matchingConverter.toFirestore(object),
      'mid',
      true
    );

    // 2. application 에 매칭 진행중이라는 isMatching 값 넣어주기
    menteeList.map(async (mentee) => {
      const doc = await setDocument(
        FirestorePath.application(mentee.aid),
        { ...mentee, matchingStatus: 1 }, //0: 매칭전, 1: 매칭중(멘토가 골랐음), 2:매칭완료
        true
      );
      if (doc === undefined) {
        // console.log('no data');
      } else {
        // console.log('do something');
        // console.log(doc);
      }
    });
    setOpen(false);
  };

  if (user) {
    return (
      <Box>
        <Button onClick={handleClickOpen}>매칭하기</Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 20 },
          }}
        >
          <Box className="flex flex-col justify-center items-center p-10 gap-4 min-w-max">
            <Box className="px-10">
              <DialogTitle id="alert-dialog-title">신청하시겠습니까?</DialogTitle>
            </Box>
            {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
          삭제하기를 클릭하시면 멘티 멘토링 정보가 영구히 삭제됩니다.
          </DialogContentText>
        </DialogContent> */}
            <DialogActions className="flex w-full">
              <Box className="flex-1 flex justify-center items-center w-20 h-14 rounded-xl ">
                <Button variant="text" onClick={handleClose} sx={{ color: 'black' }}>
                  취소
                </Button>
              </Box>
              <Box className="flex-1 flex justify-center items-center w-20 h-14 bg-[#E4782A] rounded-xl ">
                <Button variant="text" onClick={saveMatchingData} autoFocus sx={{ color: 'white' }}>
                  신청하기
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    );
  } else {
    return <Box> </Box>;
  }
};

export default SelectMenteeTest;

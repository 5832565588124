import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlice';
import { useEffect, useState } from 'react';

const AdminProtectedRoute = ({ children }) => {
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    // Simulate waiting for user data to be fully loaded
    const timer = setTimeout(() => {
      if (!user) {
        // User data is not available
        console.log('User not logged in');
        setRedirectPath('/'); // Redirect to home if not logged in
      } else if (user.userType !== 'admin') {
        // User is not an admin
        console.log('User is not an admin');
        setRedirectPath('/'); // Redirect to home if not an admin
      }
      setLoading(false); // Set loading to false after checks
    }, 2000); // Adjust delay as needed to simulate loading time

    return () => clearTimeout(timer); // Cleanup timeout when component unmounts
  }, [user]);

  // Show a loading spinner or fallback UI while loading
  if (loading) {
    return <div>Loading...</div>; // Replace with your loading component or spinner
  }

  // If redirectPath is set, perform the redirect
  if (redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  // If all checks passed, render the children (protected content)
  return children;
};

export default AdminProtectedRoute;

import { Box } from '@mui/material';
import Root from 'components/Root';
import { selectUser, getCheckMentoringInfoExist } from 'store/userSlice';
import { useSelector } from 'react-redux';
import MentorApplication from './MentorApplication';
import MenteeApplication from './MenteeApplication';
import { useEffect, useState } from 'react';
import BeforeIndex from './components/BeforeIndex';
import withRouter from 'utils/withRouter';
import { Navigate } from 'react-router-dom';

const Applications = () => {
  const user = useSelector(selectUser);
  const checkApplicationExist = useSelector(getCheckMentoringInfoExist);

  const [clicked, setClicked] = useState(false);
  const [mentoringInfoExist, setMentoringInfoExist] = useState(false);

  useEffect(() => {
    if (user) {
      setMentoringInfoExist(checkApplicationExist);
    }
  }, [user, checkApplicationExist]);

  if (!user) {
    return <Navigate to="/" />;
  }

  const shouldShowForm = !mentoringInfoExist || user.userType === 'mentee';

  if (shouldShowForm) {
    return clicked ? (
      <Root activeMenu="멘토링 정보 입력하기">
        <Box className="flex flex-col items-center">
          {user.userType === 'mentor' ? <MentorApplication /> : <MenteeApplication />}
        </Box>
      </Root>
    ) : (
      <BeforeIndex setClicked={setClicked} />
    );
  }

  return <Navigate to="/mypage" />;
};

export default withRouter(Applications);
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import LogForm from './LogForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanning } from 'store/planningSlice';
import { selectUser } from 'store/userSlice';
import { fetchLessonLog, selectLogData } from 'store/lessonLogSlice';
import { showMessage } from '../../../store/messageSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const MentorLessonLog = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const logData = useSelector(selectLogData);
  const [data, setData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [detail, setDetail] = useState(null);
  const [logId, setLogId] = useState(null);

  const handleEditLog = (item) => {
    setDetail(item);
    setModalShow(true);
    setIsEdit(true);
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchLessonLog(user.uid));
      dispatch(fetchPlanning(user.uid)).then((res) => {
        const result = res.payload;
        const temp = [];
        if (result !== null) {
          Object.keys(result.lessons).forEach((key) => {
            // key를 id로 추가
            temp.push({ ...result.lessons[key], id: Number(key) });
          });
          setLessons(temp);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (logData) {
      try {
        const temp = [];
        Object.keys(logData[0])
          .filter((key) => !isNaN(key))
          .forEach((key) => {
            temp.push({ ...logData[0][key], id: Number(key) });
          });
        setData(temp);
        setLogId(logData[0].docId);
      } catch(err) {
        dispatch(showMessage({ message: '데이터를 조회하지 못했습니다. 관리자에게 문의해주세요. CODE: ML001', variant: 'error' }));
        console.error(err)
      }
    }
  }, [logData]);

  return !lessons.length ? (
    <Box> </Box>
  ) : (
    <Box className="flex flex-col justify-start px-10 py-10 md:px-10 md:py-10 my-4 md:my-2 w-full max-w-5xl box-border bg-white rounded-2xl">
      <Typography className="pb-2" variant="h2">
        수업일지
      </Typography>
      <Typography className="!font-normal pb-2">
        매 회차 수업을 진행한 이후 일지를 작성해주세요.
      </Typography>
      <Divider />
      <Box className="flex flex-col px-4 pt-4 gap-2">
        <Button
          className="!p-2 !rounded-none"
          color="error"
          onClick={() => {
            setModalShow(true);
          }}
        >
          + 일지 작성하기
        </Button>
        {data?.map((item, index) => (
          <Card sx={{ minWidth: 275, p: 2, mb: 1 }} className="flex justify-between" key={index}>
            <CardContent>
              <Box className="flex">
                <Typography gutterBottom>{item.lessonTerm}</Typography>
                <Typography className="pl-4" gutterBottom>
                  {item.lessonDate}
                </Typography>
              </Box>
              <Typography className="!font-normal">
                수업진도:
                <Typography className="pl-2 !font-normal" component="span">
                  {item.title}
                </Typography>
              </Typography>
              <Box className="flex">
                <Typography className="!font-normal">
                  학습온도:
                  <Typography className="pl-2 !font-normal" component="span">
                    {item.lessonTemp}
                  </Typography>
                </Typography>
                <Typography className="pl-4 !font-normal">
                  관계온도:
                  <Typography className="pl-2 !font-normal" component="span">
                    {item.relationTemp}
                  </Typography>
                </Typography>
              </Box>
              <Box className="flex">
                <Typography className="!font-normal">특이사항:</Typography>
                <Typography className="pl-2 !font-normal max-w-[625px]" component="span">
                  {item.note}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button size="small" color="error" onClick={() => handleEditLog(item)}>
                수정하기
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Modal
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="logComponentTitle"
        aria-describedby="logComponentTitleDescription"
      >
        <Box sx={style}>
          <LogForm
            docId={logId}
            setDocId={setLogId}
            uid={user.uid}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            planningData={lessons}
            onClose={setModalShow}
            data={detail}
            setData={setDetail}
            logData={data}
            setLogData={setData}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default MentorLessonLog;

import React, { useState } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, TextField, Grid } from '@mui/material';
import { SUBJECT_LIST } from 'constants/index';

const PriorityBasedSubjectSelection = ({ onChange }) => {
  const [priorities, setPriorities] = useState({
    first: '',
    second: '',
  });

  const [extraSubjects, setExtraSubjects] = useState('');

  const handlePriorityChange = (event, priority) => {
    const newPriorities = { ...priorities, [priority]: event.target.value };
    setPriorities(newPriorities);
    onChange(newPriorities, extraSubjects);
  };

  const handleOtherInputChange = (event) => {
    const newExtraSubjects = event.target.value;
    setExtraSubjects(newExtraSubjects);
    onChange(priorities, newExtraSubjects);
  };

  const getAvailableSubjects = (priority) => {
    const selectedSubjects = Object.values(priorities);
    return SUBJECT_LIST.filter(
      (subject) => subject === priorities[priority] || !selectedSubjects.includes(subject)
    );
  };

  return (
    <Box>
      <Typography className="text-left !font-bold pb-2">희망 과목 (우선순위)*</Typography>
      <Typography className="text-left !text-sm pb-4" color="error">
        최대 {Object.keys(priorities).length}개까지 우선순위를 정해 선택해주세요.
      </Typography>
      <Grid container spacing={2}>
        {Object.keys(priorities).map((priority, index) => (
          <Grid item xs={12} sm={6} key={priority}>
            <FormControl fullWidth size="small">
              <Typography className="text-left !text-sm pb-2">{index + 1}순위 과목</Typography>
              <Select
                value={priorities[priority]}
                onChange={(e) => handlePriorityChange(e, priority)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>선택해주세요</em>
                </MenuItem>
                {getAvailableSubjects(priority).map((subject) => (
                  <MenuItem key={subject} value={subject}>
                    {subject}
                  </MenuItem>
                ))}
              </Select>
              {priorities[priority] === '기타' && (
                <TextField
                  value={extraSubjects}
                  onChange={(e) => handleOtherInputChange(e)}
                  placeholder="원하시는 과목명을 적어주세요"
                  fullWidth
                  size="small"
                  className="mt-2"
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PriorityBasedSubjectSelection;

// matchingSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { db } from 'firebase_config';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const initialState = {
  data: null,
};

export const fetchMatchings = createAsyncThunk('matching/fetchMatchings', async () => {
  const fetchRef = collection(db, 'Matchings');
  const querySnapshot = await getDocs(fetchRef);
  const matchings = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      matchings.push({
        ...doc.data(),
        docId: doc.id,
      });
    }
  });
  return matchings;
});

export const fetchMatching = createAsyncThunk('matching/fetchMatching', async (uid) => {
  const fetchRef = collection(db, 'Matchings');
  const queryRef = query(
    fetchRef,
    where('mentorUid', '==', uid),
    where('state', '==', 1),
    where('generation', '==', GENERATION)
  );
  const querySnapshot = await getDocs(queryRef);
  let matchingData = {};
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      matchingData = {
        ...doc.data(),
        docId: doc.id,
      };
    }
  });
  return matchingData;
});

// New action to fetch all Matchings
export const fetchAllMatchings = createAsyncThunk('matching/fetchAllMatchings', async () => {
  const fetchRef = collection(db, 'Matchings');
  const queryRef = query(
    fetchRef,
    where('generation', '==', GENERATION),
    // limit(5)
  );
  const querySnapshot = await getDocs(queryRef);
  const allMatchings = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      allMatchings.push({
        ...doc.data(),
        docId: doc.id,
      });
    }
  });
  return allMatchings;
});

const matchingSlice = createSlice({
  name: 'matching',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMatching.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    // Add case for fetchAllMatchings
    builder.addCase(fetchAllMatchings.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const selectMatching = (state) => state.matching.data;

export default matchingSlice.reducer;

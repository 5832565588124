import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Root from 'components/Root';
import { showMessage } from 'store/messageSlice';
import { useDispatch } from 'react-redux';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useEffect, useState } from 'react';

const Faq = () => {
  const dispatch = useDispatch();

  return (
    <Root activeMenu="자주 묻는 질문">
      <Box className="w-full p-12 md:p-16 grow max-w-4xl m-auto box-border">
        <Box className="mb-4">
          <Typography className="pb-12" variant="h1">
            자주 묻는 질문
          </Typography>

          <Typography className="pb-4" variant="h2">
            멘토(선생님)
          </Typography>
          {/* #1 패널 */}
          <Accordion className="mb-5" defaultExpanded elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  <b>'과목 선택'</b>이 되지 않아요
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem">
                기타 과목인 경우에는 선택한 멘티들이 적어서 저희가 필요가 있는{' '}
                <b>멘티들을 직접 연결</b>해 드립니다.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #2 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  <b>멘토링 시간</b>을 변경하고 싶어요.
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem" className="whitespace-pre-line">
                {`요일과 시간은 정교하게 딱 맞는 멘티를 찾기 어려울 수 있습니다.
                과목을 클릭하시고 나오는 멘티들을 클릭해 보시면 가능 시간대가 나오는데요.
                개인적으로 조정이 `}
                <b>가능한 시간대에 있는 멘티들</b>
                {`을 선정하시면 자동 반영되어서 매칭 됩니다.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #3 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  1차 자동매칭에서 멘티 <b>매칭이 되지 못했어요.</b>
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem" className="whitespace-pre-line">
                {`많은 멘토와 멘티님들이 가장 적절하게 만나는 과정이다보니, 1, 2지망의 멘티 선택으로 매칭이
                이루어지지 않을 수 있어요. 이후 `}
                <b>기획팀에서 직접 매칭</b>
                {`을 통해 멘티를 찾아드립니다.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #4 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  <b>멘티에 대한 자세한 정보</b>를 알고싶어요.
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem" className="whitespace-pre-line">
                {`현재 멘토 조 구성과 멘토 조장 콜링중에 있습니다.`}
                <br />
                <b>멘토조 카톡방을 개설한 후 개인적으로</b>
                {` 멘티에 대한 연락처와 기도제목, 기타 정보를 알려 드립니다. 
                멘티의 섹터가 다양해 진 만큼 미리 숙지하셔야 할 사항들을 함께 전달해 드릴게요.`}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Typography className="pb-4" variant="h2">
            멘티(학생)
          </Typography>
          {/* #1 패널 */}
          <Accordion className="mb-5" defaultExpanded elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  배남프 멘토링 전체 일정이 궁금합니다.
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem" className="whitespace-pre-line">
                {/* {`☑️ 3/3: 멘토 모집 마감\n☑️ 3/4: 멘토 동행여부 검토\n’동행이란?’ 멘토와 멘티가 함께 진행하는 학습 멘토링의 과정\n☑️ 3/5: 5기 동행 멘토 발표 (1-2차로 나누어 진행)\n☑️ 3/5 - 3/18: 멘토-멘티 매칭 진행\n☑️ 3/19: 매칭 안내\n☑️ 3/19 - 3/28: 멘토-멘티 첫인사,교재 선정 및 신청\n☑️ 3/29 - 4/7: 수업 계획서 작성, 교재 배포\n☑️ 4/6: 개강 예배\n⭐️4/7 - 6/16: 멘토링 기간\n☑️ 6/29: 종강 예배`} */}
                {/* {scheduleImageUrl ? (
                  <img src={scheduleImageUrl} alt="멘토링 전체 일정" className="w-full" />
                ) : (
                  <Typography
                    fontWeight="regular"
                    fontSize="0.875rem"
                    className="whitespace-pre-line"
                  >
                    이미지를 불러오는 중입니다...
                  </Typography>
                )} */}
                <img src="/images/bnp_6th_plan.png" alt="멘토링 전체 일정" className="w-full" />
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #2 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center  gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  멘토와 멘티의 매칭은 어떻게 이루어 지나요? 수업 전 멘토의 정보를 알 수 있나요?
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem">
                신청당시 작성해 주신 사전 설문과 멘티님들과 멘토님들의 과목, 스케쥴, 경력 및 학습
                이력 등을 종합해
                <br />
                배남프팀에서 개인별 맞춤 매칭을 진행합니다.
                <br />
                <br />
                멘토 선정 여부는 배남프 전체 일정에 맞추어 멘티분들께 일괄적으로 전달드립니다.
                <br />
                멘토 분들의 상세한 정보를 수업전에 미리 알려드리지는 않는 점 양해 부탁드립니다.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #3 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  멘토 또는 수강과목 변경이 가능한가요?
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem">
                수업을 진행하시는 동안 멘토 변경 또는 과목 변경을 원하시는 경우에는 멘토 지원팀과의
                상담을 통해
                <br />
                수업 가능한 멘토님과 멘티님의 스케쥴을 조절한 후에 학기 1회에 한해 변경해 드립니다.
                <br />
                <br />
                멘토 변경 신청은 기존 멘토 또는 이메일을 통해 연락주세요!
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #4 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  멘토링 시간을 변경하고 싶어요.
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem">
                멘토링 시간은 멘토와 멘티간의 상호 의견 조율을 통해 변경이 가능합니다. 잦은 스케쥴의
                변경은 완강을 <br />
                지연시키는 요인이 되므로 초기 스케쥴을 정하실 때 신중을 기하시기를 부탁드립니다.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* #5 패널 */}
          <Accordion className="mb-5" elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex justify-center items-center gap-2">
                <Typography color="error.main">Q.</Typography>
                <Typography className="flex items-center" fontWeight="regular">
                  멘토링 과목을 추가하고 싶어요. 형제가 같은 멘토님께 수강하기를 원하는데
                  가능할까요?
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="regular" fontSize="0.875rem">
                멘티분께서 다과목을 수강하기 원하시거나, 다자녀가 같은 멘토분께 수강하기를 원하시는
                경우 사전에
                <br />
                멘토링 신청 설문시 요청사항에 자세한 내용을 기재해 주시기 바랍니다.
                <br />
                <br />
                가능한 멘토와 매칭될 수 있도록 최선을 다하겠습니다.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Box className="flex justify-center items-center mt-12">
            <Button
              variant="outlined"
              color="error"
              sx={{ padding: '0.25rem 1.25rem' }}
              onClick={() => {
                dispatch(
                  showMessage({
                    message: '지금은 준비중이에요. 조금만 기다려주세요 🙌',
                    variant: 'hardware',
                    color: 'error.main',
                  })
                );
              }}
            >
              질문 더보기
            </Button>
          </Box>
        </Box>
      </Box>
    </Root>
  );
};

export default Faq;

// ApplicationList.jsx
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';

const TABLE_COLUMN_MIN_WIDTH = 100;

const ApplicationList = ({ data }) => {
  if (!data || data.length === 0) {
    return <Typography>No applications available.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      {/* Display total count */}
      <Box p={2}>
        <Typography variant="h6">Total Unmatched Applications: {data.length}</Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>AID</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>유형</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>Email</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>Name</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>1순위 과목</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>2순위 과목</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>기타 과목</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH * 2 }}>수업 희망 연령대</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>한국어 수준</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH }}>주언어</TableCell>
            <TableCell style={{ minWidth: TABLE_COLUMN_MIN_WIDTH * 1.5 }}>
              소속 (기타소속)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((mentee) => (
            <TableRow key={mentee.aid}>
              <TableCell>{mentee.aid}</TableCell>
              <TableCell>{mentee.userType}</TableCell>
              <TableCell>{mentee.email}</TableCell>
              <TableCell>{mentee.name}</TableCell>
              <TableCell>{mentee.prioritySubjects?.first ?? '-'}</TableCell>
              <TableCell>{mentee.prioritySubjects?.second ?? '-'}</TableCell>
              <TableCell>{mentee.extraSubjects || '-'}</TableCell>
              <TableCell>{mentee.availableCourses || '-'}</TableCell>
              <TableCell>{mentee.koreanProficiency || '-'}</TableCell>
              <TableCell>{mentee.localLanguage || '-'}</TableCell>
              <TableCell>
                {mentee.belong ? `${mentee.belong} (${mentee.extraBelong || '-'})` : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApplicationList;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {
  CustomTimeCheckbox,
  CustomBasicCheckbox,
  CustomSquareCheckbox,
} from 'elements/CustomCheckbox';
import {
  saveApplication,
  selectApplication,
  setApplication,
  setInit,
} from 'store/applicationSlice';
import { showMessage } from 'store/messageSlice';
import { selectUser, updateUser } from 'store/userSlice';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';
import { BELONG_LIST, CLASS_TIMES, COURSE_LIST, DAYS, SUBJECT_LIST } from 'constants/index';

const MentorDetail = ({ rows, step, setStep, actionPrevStep }) => {
  const user = useSelector(selectUser);
  const mentor = useSelector(selectApplication).mentor;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    availableSubjects: rows.availableSubjects,
    availableCourses: rows.availableCourses,
    availableDay: [],
    availableTime: rows.availableDateTime,
    pledge: rows.pledge,
    extraSubjects: "",
    extraBelong: [], // 필드명을 extraBelong으로 변경
  });

  const { currentGeneration } = fetchRemoteConfigValues();

  const handleCheckbox = (value, type) => {
    setFormData((prevData) => {
      const updatedList = prevData[type].includes(value)
        ? prevData[type].filter((item) => item !== value)
        : [...prevData[type], value];

      if (type === 'availableSubjects' && value === '기타') {
        return {
          ...prevData,
          [type]: updatedList,
          extraSubjects: updatedList.includes('기타') ? "" : prevData.extraSubjects,
        };
      }

      return { ...prevData, [type]: updatedList };
    });
  };

  const handleDayCheckbox = (day) => {
    setFormData((prevData) => {
      const updatedDay = prevData.availableDay.includes(day)
        ? prevData.availableDay.filter((item) => item !== day)
        : [...prevData.availableDay, day];

      const updatedTime = prevData.availableTime.filter((time) => !time.startsWith(day));
      if (updatedDay.includes(day)) {
        CLASS_TIMES.forEach((time) => {
          const newTime = `${day}-${time}`;
          if (!updatedTime.includes(newTime)) {
            updatedTime.push(newTime);
          }
        });
      }

      return { ...prevData, availableDay: updatedDay, availableTime: updatedTime };
    });
  };

  // extraBelong 선택 핸들러 (다중 선택 지원)
  const handleExtraBelongChange = (belong) => {
    setFormData((prevData) => {
      const updatedBelong = prevData.extraBelong.includes(belong)
        ? prevData.extraBelong.filter((item) => item !== belong)
        : [...prevData.extraBelong, belong];
      return { ...prevData, extraBelong: updatedBelong };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const newData = {
      ...Object.values(mentor)[0],
      ...Object.values(mentor)[1],
      ...formData,
      uid: user.uid,
      email: user.email,
      userType: user.userType,
      matchingStatus: 0,
      generation: currentGeneration,
    };

    dispatch(setApplication({ step, data: formData, type: 'mentor' }));
    dispatch(saveApplication({ data: newData, type: 'mentor' }))
      .then((v) => {
        if (v !== undefined) {
          dispatch(
            updateUser({
              ...user,
              pledge: formData.pledge,
              mentoringInfoExist: true,
            })
          );
          setStep(step + 1);
          dispatch(setInit());
        } else {
          dispatch(showMessage({ message: '정보 저장에 실패했습니다.', variant: 'error' }));
        }
      })
      .finally(() => setLoading(false));
  };

  const validateForm = () => {
    if (formData.availableSubjects.includes('기타') && !formData.extraSubjects) {
      dispatch(showMessage({ message: '희망 과목을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!formData.availableSubjects.length) {
      dispatch(showMessage({ message: '수업 가능 과목을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!formData.availableCourses.length) {
      dispatch(showMessage({ message: '희망 수업 과정을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!formData.availableTime.length) {
      dispatch(showMessage({ message: '수업 가능 시간을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!formData.pledge) {
      dispatch(showMessage({ message: '교재 사용 안내를 확인해주세요.', variant: 'error' }));
      return false;
    }
    if (!formData.extraBelong.length) {
      dispatch(showMessage({ message: '소속을 선택해주세요.', variant: 'error' })); // extraBelong 유효성 검사
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Box className="text-center">
        <Typography variant="h1">멘토링 정보 입력하기</Typography>
        <Typography component="p" color="error" variant="body3" className="!mt-4">
          * 표시된 항목은 필수 입력 항목입니다.
        </Typography>
      </Box>
      <Box className="p-12 md:p-16" component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <ExtraBelongSelection extraBelong={formData.extraBelong} handleExtraBelongChange={handleExtraBelongChange} />
          <SubjectsSection
            availableSubjects={formData.availableSubjects}
            extraSubjects={formData.extraSubjects}
            handleCheckbox={handleCheckbox}
            setFormData={setFormData}
          />
          <CoursesSection
            availableCourses={formData.availableCourses}
            handleCheckbox={handleCheckbox}
          />
          <AvailableTimeSection
            availableDay={formData.availableDay}
            availableTime={formData.availableTime}
            handleDayCheckbox={handleDayCheckbox}
            handleCheckbox={handleCheckbox}
          />
          <CopyrightNoticeSection
            pledge={formData.pledge}
            setPledge={(value) => setFormData((prev) => ({ ...prev, pledge: value }))}
          />
        </Grid>
        <ActionButtons actionPrevStep={actionPrevStep} />
      </Box>
      <LoadingBackdrop open={loading} />
    </Box>
  );
};


const SubjectsSection = ({ availableSubjects, extraSubjects, handleCheckbox, setFormData }) => (
  <>
    <Grid item xs={12} columnGap={1} columnSpacing={1}>
      <Typography className="text-left !font-bold pb-2">수업 가능 과목*</Typography>
      <Typography className="text-left !text-xs pb-4" color={(theme) => theme.palette.error.main}>
        다중 선택이 가능합니다.
      </Typography>
      <Box className="flex flex-wrap">
        {SUBJECT_LIST.map((item, index) => (
          <CustomBasicCheckbox
            key={index}
            name="availableSubjects"
            label={item}
            data={availableSubjects}
            actionChange={() => handleCheckbox(item, 'availableSubjects')}
          />
        ))}
      </Box>
    </Grid>
    {availableSubjects.includes('기타') && (
      <Grid item xs={12}>
        <Typography className="text-left !font-bold pb-2">희망 과목 작성 (기타 선택 시)</Typography>
        <TextField
          id="extraSubjects"
          name="extraSubjects"
          variant="standard"
          placeholder="희망하시는 과목을 입력해주세요."
          InputProps={{ className: 'p-2' }}
          value={extraSubjects}
          onChange={(e) => setFormData((prev) => ({ ...prev, extraSubjects: e.target.value }))}
          hiddenLabel
          fullWidth
        />
      </Grid>
    )}
  </>
);

const CoursesSection = ({ availableCourses, handleCheckbox }) => (
  <Grid item xs={12}>
    <div className={'mb-4'}>
      <Typography className="text-left !font-bold pb-2">희망 수업 과정*</Typography>
      <Typography className="text-left !text-xs" color={(theme) => theme.palette.error.main}>
        다중 선택이 가능합니다. 최대한 많은 학년을 선택해주시면 매칭이 수월합니다 :)
      </Typography>
    </div>
    <Box className="flex flex-wrap">
      {COURSE_LIST.map((item, index) => (
        <CustomBasicCheckbox
          key={index}
          name="availableCourses"
          label={item}
          data={availableCourses}
          actionChange={() => handleCheckbox(item, 'availableCourses')}
        />
      ))}
    </Box>
  </Grid>
);

const AvailableTimeSection = ({
                                availableDay,
                                availableTime,
                                handleDayCheckbox,
                                handleCheckbox,
                              }) => (
  <Grid item xs={12}>
    <div className={'mb-4'}>
      <Typography className="text-left !font-bold pb-2">수업 가능 시간대*</Typography>
      <Typography className="text-left !text-xs pb-2" color={(theme) => theme.palette.error.main}>
        한국 시간을 기준으로 선택하세요. (시간대는 다중 선택이 가능합니다.)
      </Typography>
      <Typography className="text-left !text-xs" color={(theme) => theme.palette.error.main}>
        요일을 클릭하시면 전체 시간대가 선택됩니다.
      </Typography>
    </div>
    <Box className="relative">
      {DAYS.map((item, index) => (
        <Box key={index} className="flex items-center gap-2 py-1">
          <CustomSquareCheckbox
            name="availableDay"
            label={item}
            data={availableDay}
            actionChange={() => handleDayCheckbox(item)}
          />
          <Box className="inline-flex w-full overflow-auto">
            {CLASS_TIMES.map((val) => (
              <CustomTimeCheckbox
                key={val}
                name="availableTime"
                label={val}
                dataKey={DAYS[index]}
                data={availableTime}
                actionChange={() => handleCheckbox(`${DAYS[index]}-${val}`, 'availableTime')}
              />
            ))}
          </Box>
          <div className="absolute right-0 w-8 h-11 bg-gradient-to-l from-blue-50"></div>
        </Box>
      ))}
    </Box>
  </Grid>
);

const CopyrightNoticeSection = ({ pledge, setPledge }) => (
  <Grid item xs={12}>
    <Box
      className="flex flex-col gap-7 justify-center items-center px-16 py-10 w-full bg-[#FFDCB8]"
      component="span"
      sx={{ border: '2px dashed #E4782A' }}
    >
      <Typography className="text-center" variant="h1">
        교재 사용에 관한 저작권 안내
      </Typography>
      <Typography className="text-left" fontWeight="regular">
        아래와 같은 행위를 할 경우 법적인 문제가 발생할 수 있음을 알려드립니다.
        <br />
        <br />
        • 배남프 수업 이외의 목적으로 사용하는 경우
        <br />
        • 개인의 영리를 위해 사용하는 경우
        <br />
        • 타인과 공유하는 경우
        <br />
      </Typography>
      <Box className="flex items-center">
        <Checkbox
          value={pledge}
          checked={pledge}
          onChange={() => setPledge(!pledge)}
          color="primary"
        />
        <Typography variant="body3">위 내용을 확인했습니다 (필수)</Typography>
      </Box>
    </Box>
  </Grid>
);

const ActionButtons = ({ actionPrevStep }) => (
  <Box className="flex flex-row items-center justify-center pt-24">
    <Button
      fullWidth
      className="formButton"
      variant="contained"
      color="inherit"
      onClick={actionPrevStep}
    >
      이전
    </Button>
    <Button fullWidth className="formButton" type="submit" variant="contained">
      신청완료
    </Button>
  </Box>
);

const LoadingBackdrop = ({ open }) => (
  <Backdrop
    sx={{
      color: (theme) => theme.palette.primary.main,
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
    open={open}
  >
    <Box className="flex justify-center items-center p-8 bg-white rounded-lg">
      <CircularProgress color="inherit" />
    </Box>
  </Backdrop>
);

const ExtraBelongSelection = ({ extraBelong, handleExtraBelongChange }) => {
  // Filter BELONG_LIST to exclude "기타"
  const filteredBelongList = BELONG_LIST.filter((item) => item !== '기타');

  return (
    <Grid item xs={12}>
      <Typography className="text-left !font-bold pb-2">희망 멘티 선택*</Typography>
      <Typography className="text-left !text-xs pb-4" color={(theme) => theme.palette.error.main}>
        해외 거주: 선교지에 있는 선교사님들의 자녀들이에요.
        <br/>온누리 M 센터: 온누리M센터에 소속되어 선교사님들의 도움을 받고 있는 다문화/이주민 가정의 멘티들이에요.
        <br/>국내 지역교회: 아웃리치 연계 국내 지역교회의 목회자 자녀들 또는 해당 교회 청소년이에요.
      </Typography>
      <Box className="flex flex-wrap">
        {filteredBelongList.map((item, index) => (
          <CustomBasicCheckbox
            key={index}
            name="extraBelong"
            label={item}
            data={extraBelong} // 배열 형태로 전달
            actionChange={() => handleExtraBelongChange(item)}
          />
        ))}
      </Box>
    </Grid>
  );
};


export default MentorDetail;

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { defaultTheme } from 'themes';
import store from 'store';
import Router from 'routes/Router';
import Message from 'utils/message/Message';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { fetchAndActivate, firebaseAuth, getValue, remoteConfig } from 'firebase_config';
import { signIn, checkMentoringInfoExist } from 'store/userSlice';
import { showMessage } from 'store/messageSlice';

function App() {
  const dispatch = useDispatch();

  // check at page load if a user is authenticated
  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (userAuth) => {
      if (userAuth && userAuth.emailVerified) {
        dispatch(signIn(userAuth));
        dispatch(checkMentoringInfoExist(userAuth.uid));
      } else {
        return;
      }
    });
  }, []);

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then((value) => {
        // console.log(value); // remote 변경사항 있으면 true, 없거나 interval 안지났으면 false
        // console.log(getValue(remoteConfig, 'open_datetime_matching')._value);
        // console.log(getValue(remoteConfig, 'open_datetime_syllabus')._value);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Router />
        <Message />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

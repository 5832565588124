export class ApplicationModel {
  constructor(
    email,
    belong,
    extraBelong,
    prayerRequest,
    aid,
    uid,
    userType,
    pledge,
    photoPath,
    name,
    gender,
    extraSubjects,
    contact,
    birth,
    availableSubjects,
    availableDateTime,
    availableCourses,
    createdAt,
    matchingStatus,
    matchingStatusBySubject,
    prioritySubjects,
    koreanProficiency,
    localLanguage,
    generation,
    guardianContact,
    additionalRequirements,
    tmpMatchedStatus,
    tmpMatchedSubjects
  ) {
    this.email = email;
    this.belong = belong;
    this.extraBelong = extraBelong;
    this.prayerRequest = prayerRequest;
    this.aid = aid;
    this.uid = uid;
    this.userType = userType;
    this.pledge = pledge;
    this.photoPath = photoPath;
    this.name = name;
    this.gender = gender;
    this.extraSubjects = extraSubjects;
    this.contact = contact;
    this.birth = birth;
    this.availableSubjects = availableSubjects;
    this.availableDateTime = availableDateTime;
    this.availableCourses = availableCourses;
    this.createdAt = createdAt;
    this.matchingStatus = matchingStatus;
    this.matchingStatusBySubject = matchingStatusBySubject;
    this.prioritySubjects = prioritySubjects;
    this.koreanProficiency = koreanProficiency;
    this.localLanguage = localLanguage;
    this.generation = generation;
    this.guardianContact = guardianContact;
    this.additionalRequirements = additionalRequirements;
    this.tmpMatchedStatus = tmpMatchedStatus;
    this.tmpMatchedSubjects = tmpMatchedSubjects;
  }

  toString() {
    return `
    ${this.email}, 
    ${this.belong}, 
    ${this.extraBelong}, 
    ${this.prayerRequest}, 
    ${this.aid},
    ${this.uid}, 
    ${this.userType}, 
    ${this.pledge}, 
    ${this.photoPath}, 
    ${this.name}, 
    ${this.gender}, 
    ${this.extraSubjects}, 
    ${this.contact}, 
    ${this.birth}, 
    ${this.availableSubjects}, 
    ${this.availableDateTime}, 
    ${this.availableCourses}, 
    ${this.createdAt}, 
    ${this.matchingStatus}, 
    ${this.matchingStatusBySubject}, 
    ${this.prioritySubjects}, 
    ${this.koreanProficiency}, 
    ${this.localLanguage}, 
    ${this.generation},
    ${this.guardianContact},
    ${this.additionalRequirements},
    ${this.tmpMatchedStatus},
    ${this.tmpMatchedSubjects},
    `;
  }
}

// Firestore data converter
const applicationConverter = {
  toFirestore: (application) => {
    return {
      email: application.email,
      belong: application.belong,
      extraBelong: application.extraBelong,
      prayerRequest: application.prayerRequest,
      aid: application.aid,
      uid: application.uid,
      userType: application.userType,
      pledge: application.pledge,
      photoPath: application.photoPath,
      name: application.name,
      gender: application.gender,
      extraSubjects: application.extraSubjects,
      contact: application.contact,
      birth: application.birth,
      availableSubjects: application.availableSubjects,
      availableDateTime: application.availableDateTime,
      availableCourses: application.availableCourses,
      createdAt: application.createdAt,
      matchingStatus: application.matchingStatus,
      matchingStatusBySubject: application.matchingStatusBySubject,
      prioritySubjects: application.prioritySubjects,
      koreanProficiency: application.koreanProficiency,
      localLanguage: application.localLanguage,
      generation: application.generation,
      guardianContact: application.guardianContact,
      additionalRequirements: application.additionalRequirements,
      tmpMatchedStatus: application.tmpMatchedStatus,
      tmpMatchedSubjects: application.tmpMatchedSubjects,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ApplicationModel(
      data.email,
      data.belong ?? null,
      data.extraBelong ?? null,
      data.prayerRequest ?? null,
      data.aid,
      data.uid,
      data.userType,
      data.pledge,
      data.photoPath ?? null,
      data.name,
      data.gender,
      data.extraSubjects ?? null,
      data.contact,
      data.birth,
      data.availableSubjects,
      data.availableDateTime,
      data.availableCourses,
      data.createdAt,
      data.matchingStatus ?? 0,
      data.matchingStatusBySubject ?? {},
      data.prioritySubjects ?? null,
      data.koreanProficiency ?? null,
      data.localLanguage ?? null,
      data.generation ?? null,
      data.guardianContact ?? null,
      data.additionalRequirements ?? null,
      data.tmpMatchedStatus ?? null,
      data.tmpMatchedSubjects ?? null
    );
  },
};

export default applicationConverter;

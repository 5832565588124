import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { collection, getDocs, query, serverTimestamp, where } from 'firebase/firestore';
import { db } from 'firebase_config';
import applicationConverter from 'models/application_model';
import FirestorePath from 'services/firebase_path';
import { addDocument, getCollectionFuture } from 'services/firebase_services';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const initialState = {
  data: {},
  mentor: {
    0: {
      type: 'mentor',
      name: '',
      contact: '',
      gender: '',
      birth: '',
    },
    1: {
      availableSubjects: [],
      extraSubjects: '',
      availableCourses: [],
      availableDateTime: [],
      pledge: false, // 서약서 체크박스
    },
  },
  mentee: {
    0: {
      status: false,
      type: 'mentee',
      name: '',
      contact: '',
      birth: '',
      gender: '',
      belong: '',
      extraBelong: '',
      availableSubjects: [],
      extraSubjects: '',
      availableCourses: [],
      availableDateTime: [],
      photoUrl: '', // 멘티사진
      prayerRequest: [], // 기도제목
      pledge: false, // 서약서 체크박스
    },
  },
};

// New Thunk for fetching unmatched applications
export const fetchUnmatchedApplications = createAsyncThunk(
  'application/fetchUnmatchedApplications',
  async () => {
    // Fetch applications where tmpMatchedStatus is 0
    const queryConstraints = [
      where('tmpMatchedStatus', '==', 0), // Condition for unmatched applications
      where('userType', '==', 'mentee'), // Current generation filter
      where('generation', '==', GENERATION), // Current generation filter
    ];

    // Fetching data from Firestore
    const docs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => applicationConverter.fromFirestore(data),
      queryConstraints
    );

    return docs;
  }
);

// 멘토에게 선택받지 못한 멘티 리스트 불러오는 함수
export const fetchUnSelectedApplications = createAsyncThunk(
  'application/fetchUnSelectedApplications',
  async () => {
    // Fetch applications where tmpMatchedStatus is 0
    const queryConstraints = [
      // where('matchingStatus', '==', 0), // Condition for unmatched applications
      where('userType', '==', 'mentee'), // Current generation filter
      where('generation', '==', GENERATION), // Current generation filter
    ];

    // Fetching data from Firestore
    const docs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => applicationConverter.fromFirestore(data),
      queryConstraints
    );

    // 실 환경에서 테스트 계정 멘티는 제거
    const withoutTester = docs.filter(
      (doc) =>
        doc.email !== 'bhgt9510@naver.com' &&
        doc.email !== 'bhgt4563@gmail.com' &&
        doc.email !== 'baenampit@gmail.com' &&
        doc.email !== 'baenamproject@gmail.com'
    );
    // const withoutTester =
    //   process.env.REACT_APP_FB_NODE_ENV === 'development'
    //     ? docs
    //     : docs.filter(
    //         (doc) =>
    //           doc.email !== 'bhgt9510@naver.com' &&
    //           doc.email !== 'bhgt4563@gmail.com' &&
    //           doc.email !== 'baenampit@gmail.com' &&
    //           doc.email !== 'baenamproject@gmail.com'
    //       );

    const result = withoutTester.filter(
      (query) => query.tmpMatchedStatus === null || query.tmpMatchedStatus === 0
    );

    return result;
  }
);

export const saveApplication = createAsyncThunk(
  'application/saveApplication',
  async ({ data, type }) => {
    // type: mentor, mentee
    // data: application data
    const newData = { ...data, createdAt: serverTimestamp() };

    // DB에 저장
    const docId = await addDocument(FirestorePath.applications(), newData, 'aid', true);
    return docId;
  }
);

export const fetchApplication = createAsyncThunk('application/fetchApplication', async (uid) => {
  // DB에서 가져오기
  const fetchRef = collection(db, 'Applications');
  const queryRef = query(fetchRef, where('uid', '==', uid));
  const querySnapshot = await getDocs(queryRef);
  let applicationData;
  querySnapshot.forEach((d) => {
    const newData = {
      ...d.data(),
      // docId: d.id,
      createdAt: dayjs.unix(d.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    applicationData = newData;
  });
  return applicationData;
});

export const fetchMenteeApplication = createAsyncThunk(
  'application/fetchMenteeApplication',
  async ({ subject, course, belong }) => {
    const queryConstraints = [
      where('userType', '==', 'mentee'),
      where('generation', '==', GENERATION),
      where('matchingStatus', '<', 2),
    ];

    // Firestore에서 멘티 신청서 가져오기
    const docs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => applicationConverter.fromFirestore(data),
      queryConstraints
    );

    // 실 환경에서 테스트 계정 멘티는 제거
    const temp =
      process.env.REACT_APP_FB_NODE_ENV === 'development'
        ? docs
        : docs.filter(
            (doc) =>
              doc.email !== 'bhgt9510@naver.com' &&
              doc.email !== 'bhgt4563@gmail.com' &&
              doc.email !== 'cwcw0908@gmail.com' &&
              doc.email !== 'ljeongmin0908@gmail.com'
          );

    // 임시 매칭 된 멘티 제외하고 보이도록
    const result = temp.filter(
      (query) => query.tmpMatchedStatus === null || query.tmpMatchedStatus === 0
    );

    // 모든 조건을 AND 연산으로 통합
    const filteredMentees = result.filter((mentee) => {
      // subject가 배열인지 단일 값인지 확인
      let subjectMatches = true;
      if (subject) {
        if (Array.isArray(subject)) {
          subjectMatches = subject.some((subj) => mentee.availableSubjects?.includes(subj));
        } else {
          subjectMatches = mentee.availableSubjects?.includes(subject);
        }
      }

      const courseMatches =
        !course || course.length === 0 || course.some((c) => mentee.availableCourses.includes(c));

      const belongMatches = !belong || belong.length === 0 || belong.includes(mentee.belong);

      // **추가된 부분: matchingStatusBySubject 필터링**
      let statusMatches = true;
      if (subject) {
        if (Array.isArray(subject)) {
          // subject가 배열일 때, 하나라도 matchingStatusBySubject가 2 이상인 경우 제외
          statusMatches = !subject.some(
            (subj) => (mentee.matchingStatusBySubject?.[subj] || 0) >= 2
          );
        } else {
          // subject가 단일 값일 때, 해당 subject의 matchingStatusBySubject가 2 이상인 경우 제외
          statusMatches = (mentee.matchingStatusBySubject?.[subject] || 0) < 2;
        }
      }

      // 모든 조건을 만족하는 멘티를 필터링
      return subjectMatches && courseMatches && belongMatches && statusMatches;
    });

    // course 일치 여부에 따라 정렬 (선택 사항)
    const menteesMatchingCourse = filteredMentees.filter((mentee) => {
      return course && course.length > 0 && course.some((c) => mentee.availableCourses.includes(c));
    });

    const menteesNotMatchingCourse = filteredMentees.filter((mentee) => {
      return !menteesMatchingCourse.includes(mentee);
    });

    return [...menteesMatchingCourse, ...menteesNotMatchingCourse];
  }
);

export const fetchApplicationsByAids = createAsyncThunk(
  'application/fetchApplicationsByAids',
  async (aids) => {
    const applications = {};
    for (const aid of aids) {
      const appQuery = query(collection(db, 'Applications'), where('aid', '==', aid));
      const querySnapshot = await getDocs(appQuery);
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          applications[aid] = { ...doc.data(), docId: doc.id };
        }
      });
    }
    return applications;
  }
);

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplication: (state, action) => {
      const { step, data, type } = action.payload;
      Object.entries(data).forEach(([key, value]) => {
        state[type][step][key] = value;
      });
    },
    setInit: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplication.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchMenteeApplication.fulfilled, (state, action) => {
        state.matchingData = action.payload;
      })
      .addCase(fetchApplicationsByAids.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchApplicationsByAids.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchUnmatchedApplications.fulfilled, (state, action) => {
        state.unmatchedApplications = action.payload; // Store unmatched applications
      })
      .addCase(fetchUnSelectedApplications.fulfilled, (state, action) => {
        state.unSelectedApplications = action.payload; // Store unmatched applications
      });
  },
});

export const selectApplication = (state) => state.application;
export const selectApplicationData = (state) => state.application.data;
export const selectMatchingData = (state) => state.application.matchingData;
export const selectUnmatchedApplications = (state) => state.application.unmatchedApplications; // Selector for unmatched applications
export const selectUnselectedApplications = (state) => state.application.unSelectedApplications; // Selector for unmatched applications

export const { setApplication, setInit } = applicationSlice.actions;

export default applicationSlice.reducer;

import { Box, Dialog, Typography, Grid } from '@mui/material';
import Info from './components/Info';
import TimeBlocks from './components/TimeBlocks';

const MenteeInfoDialog = (props) => {
  const { open, handleClose, mentee, mentor } = props;

  // Extract prioritySubjects with fallback handling
  const prioritySubjects = mentee.prioritySubjects || {};
  const firstSubject = prioritySubjects.first === '기타' ? `기타-${mentee.extraSubjects}` : prioritySubjects.first;
  const secondSubject = prioritySubjects.second === '기타' ? mentee.extraSubjects : prioritySubjects.second;
  const koreanProficiency = mentee.koreanProficiency || '기입하지 않음';
  const localLanguage = mentee.localLanguage || '기입하지 않음';
  const extraRequests = mentee.extraRequests || '없음';

  // Define a variable for the Typography variant to control font size easily
  const textVariant = 'subtitle1'; // Change this to adjust font size (e.g., 'body1', 'h6')

  return (
    <Dialog
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 20, width: '70%' },
      }}
    >
      <Box className="flex flex-col justify-center items-center p-10 gap-4">
        <Box className="flex flex-col w-full p-8 gap-4">
          <Info partner={mentee} contactVisible={false} />
          <Grid container spacing={4} className="px-12">
            <Grid item xs={6}>
              <Typography variant={textVariant} fontWeight="bold" color="primary">
                - 신청 과목 1: {firstSubject}
              </Typography>
              <Typography variant={textVariant} fontWeight="bold" color="primary">
                - 신청 과목 2: {secondSubject}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={textVariant} fontWeight="bold" color="secondary">
                - 한국어 수준: {koreanProficiency}
              </Typography>
              <Typography variant={textVariant} fontWeight="bold" color="secondary">
                - 주언어: {localLanguage}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={textVariant} fontWeight="bold" color="textSecondary">
                - 추가적인 요청사항: {extraRequests}
              </Typography>
            </Grid>
          </Grid>
          <TimeBlocks mentee={mentee} mentor={mentor} />
        </Box>
        <Box
          className="flex justify-center items-center w-1/2 h-14 bg-[#E4782A] rounded-xl cursor-pointer"
          onClick={handleClose}
          sx={{ color: 'white' }}
        >
          닫기
        </Box>
      </Box>
    </Dialog>
  );
};

export default MenteeInfoDialog;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { saveApplication, setApplication, setInit } from 'store/applicationSlice';
import { showMessage } from 'store/messageSlice';
import { firebaseStorage, ref, getDownloadURL, uploadBytes } from 'firebase_config';
import { selectUser } from 'store/userSlice';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';
import PriorityBasedSubjectSelection from './PriorityBasedSubjectSelection';
import {
  CustomBasicCheckbox as BasicCheckbox,
  CustomSquareCheckbox as SquareCheckbox,
  CustomTimeCheckbox as TimeCheckbox,
} from 'elements/CustomCheckbox';
import Radio from 'elements/CustomRadio';
import {
  CLASS_TIMES,
  DAYS,
  COURSE_LIST,
  BELONG_LIST,
  EXTRA_BELONG_LIST,
  KOREAN_PROFICIENCY_LEVELS,
} from 'constants/index';

const MenteeInfo = ({ rows, step, setStep, actionPrevStep }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { currentGeneration } = fetchRemoteConfigValues();

  // State Hooks for Form Data
  const [formData, setFormData] = useState({
    name: rows.name || '',
    birth: rows.birth || '',
    contact: rows.contact || '',
    gender: rows.gender || '',
    belong: rows.belong || 'none',
    extraBelong: rows.extraBelong || '',
    guardianName: rows.guardianName || '',
    guardianContact: rows.guardianContact || '',
    prioritySubjects: rows.prioritySubjects || {},
    availableSubjects: rows.availableSubjects || [],
    availableCourses: rows.availableCourses || [],
    availableDay: [],
    availableTime: rows.availableDateTime || [],
    pledge: rows.pledge || false,
    pray1: '',
    pray2: '',
    pray3: '',
    localLanguage: rows.localLanguage || '',
    koreanProficiency: rows.koreanProficiency || '',
    additionalRequirements: '',
  });

  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');

  // Effect Hook to handle available days
  useEffect(() => {
    const days = formData.availableTime.map((time) => time.split('-')[0]);
    setFormData((prev) => ({ ...prev, availableDay: [...new Set(days)] }));
  }, [formData.availableTime]);

  // Handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleGenderChange = (value) => {
    setFormData((prev) => ({ ...prev, gender: value }));
  };

  const handleBelongChange = (event) => {
    setFormData((prev) => ({ ...prev, belong: event.target.value }));
  };

  const handlePledgeChange = () => {
    setFormData((prev) => ({ ...prev, pledge: !prev.pledge }));
  };

  const handlePrayRequestChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= 30) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageName(file.name);
      setImageUpload(file);
    } else {
      setSelectedImageName('');
      setImageUpload(null);
    }
  };

  const handleCheckboxChange = (value, type) => {
    setFormData((prev) => {
      let list = [...prev[type]];
      if (list.includes(value)) {
        list = list.filter((item) => item !== value);
        if (type === 'availableDay') {
          const filteredTime = prev.availableTime.filter((time) => !time.startsWith(`${value}-`));
          return { ...prev, [type]: list, availableTime: filteredTime };
        }
      } else {
        list.push(value);
        if (type === 'availableDay') {
          const newTimes = CLASS_TIMES.map((time) => `${value}-${time}`);
          return { ...prev, [type]: list, availableTime: [...prev.availableTime, ...newTimes] };
        }
      }
      return { ...prev, [type]: list };
    });
  };

  const handlePrioritySubjectsChange = (subjects, extraSubjects) => {
    setFormData((prev) => ({
      ...prev,
      prioritySubjects: subjects,
      extraSubjects: extraSubjects,
      availableSubjects: Object.values(subjects).filter((value) => value !== ''),
    }));
  };

  const validateForm = () => {
    const {
      name,
      contact,
      gender,
      birth,
      belong,
      extraBelong,
      availableCourses,
      availableTime,
      pledge,
      pray1,
      pray2,
      pray3,
      prioritySubjects,
      localLanguage,
      koreanProficiency,
      additionalRequirements, // **추가된 필드 검증**
    } = formData;

    if (prioritySubjects.length === 0) {
      dispatch(showMessage({ message: '희망 과목을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!name) {
      dispatch(showMessage({ message: '이름을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!contact) {
      dispatch(showMessage({ message: '연락처(이메일)을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!gender) {
      dispatch(showMessage({ message: '성별을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!birth) {
      dispatch(showMessage({ message: '생년월일을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!belong || belong === 'none') {
      dispatch(showMessage({ message: '소속을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (belong === '기타' && !extraBelong) {
      dispatch(showMessage({ message: '소속을 작성해주세요.', variant: 'error' }));
      return false;
    }
    if (availableCourses.length === 0) {
      dispatch(showMessage({ message: '희망 수업 과정을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!localLanguage) {
      dispatch(
        showMessage({ message: '현지에서 주로 사용하는 언어를 입력해주세요.', variant: 'error' })
      );
      return false;
    }
    if (!koreanProficiency) {
      dispatch(showMessage({ message: '한국어 수준을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (availableTime.length === 0) {
      dispatch(showMessage({ message: '수업 가능 시간을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!imageUpload) {
      dispatch(showMessage({ message: '멘티 사진을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!pray1 && !pray2 && !pray3) {
      dispatch(showMessage({ message: '기도제목을 입력해주세요.', variant: 'error' }));
      return false;
    }
    // **추가된 필드 검증 (필수일 경우)**
    if (!additionalRequirements) {
      dispatch(showMessage({ message: '추가 요구사항을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!pledge) {
      dispatch(showMessage({ message: '교재 사용 안내를 확인해주세요.', variant: 'error' }));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    // Upload Image to Firebase Storage
    const date = new Date().toISOString();
    const photoPath = `mentees/${formData.name}_${formData.birth.replace(/-/g, '')}_${date.replace(
      /[-:.]/g,
      ''
    )}`;

    const imageRef = ref(firebaseStorage, photoPath);
    let imageUrl = '';

    try {
      const snapshot = await uploadBytes(imageRef, imageUpload);
      imageUrl = await getDownloadURL(snapshot.ref);
    } catch (error) {
      dispatch(
        showMessage({
          message: '이미지 업로드에 실패하였습니다. 파일을 확인해주세요.',
          variant: 'error',
        })
      );
      setLoading(false);
      return;
    }

    // Prepare Data
    const data = {
      ...formData,
      availableDateTime: formData.availableTime,
      prayerRequest: [formData.pray1, formData.pray2, formData.pray3].filter(Boolean),
      photoUrl: imageUrl,
      photoPath,
      uid: user.uid,
      email: user.email,
      userType: user.userType,
      matchingStatus: 0,
      matchingStatusBySubject: Object.fromEntries(
        formData.availableSubjects.map((subject) => [subject, 0])
      ),
      generation: currentGeneration,
    };

    dispatch(setApplication({ step, data, type: 'mentee' }));
    const result = await dispatch(saveApplication({ data, type: 'mentee' }));

    if (result !== undefined) {
      setStep(step + 1);
      dispatch(setInit());
    } else {
      dispatch(showMessage({ message: '정보 저장에 실패했습니다.', variant: 'error' }));
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box className="text-center">
        <Typography variant="h1">멘토링 정보 입력하기</Typography>
        <Typography component="p" color="error" variant="body3" className="!mt-4">
          * 표시된 항목은 필수 입력 항목입니다.
        </Typography>
      </Box>
      <Box className="p-10 md:p-14" component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Name */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">이름*</Typography>
            <TextField
              id="name"
              name="name"
              variant="standard"
              placeholder="이름을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {/* Birth Date */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">생년월일*</Typography>
            <TextField
              id="birth"
              name="birth"
              type="date"
              variant="standard"
              InputProps={{ className: 'p-2' }}
              value={formData.birth}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {/* Contact */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">멘티 연락처(이메일)*</Typography>
            <TextField
              id="contact"
              name="contact"
              variant="standard"
              placeholder="이메일을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              value={formData.contact}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {/* Gender */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">성별*</Typography>
            <Box className="flex items-center">
              <Radio
                title="남자"
                value="M"
                checked={formData.gender === 'M'}
                onChange={() => handleGenderChange('M')}
              />
              <Radio
                title="여자"
                value="F"
                checked={formData.gender === 'F'}
                onChange={() => handleGenderChange('F')}
              />
            </Box>
          </Grid>
          {/* Belong */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">소속*</Typography>
            <FormControl fullWidth size="small">
              <InputLabel id="belong-select-label">선택해주세요.</InputLabel>
              <Select
                labelId="belong-select-label"
                id="belong-select"
                value={formData.belong}
                onChange={handleBelongChange}
                label="선택해주세요."
              >
                <MenuItem value="none">
                  <em>선택해주세요.</em>
                </MenuItem>
                {BELONG_LIST.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Extra Belong */}
          {formData.belong !== 'none' && (
            <Grid item xs={12} sm={6}>
              <Typography className="text-left !font-bold pb-2">
                {EXTRA_BELONG_LIST[formData.belong].title} 선택 시
              </Typography>
              <TextField
                id="extraBelong"
                name="extraBelong"
                variant="standard"
                placeholder={EXTRA_BELONG_LIST[formData.belong].placeholder}
                InputProps={{ className: 'p-2' }}
                value={formData.extraBelong}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          )}
          {/* Guardian Name */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">보호자 이름*</Typography>
            <TextField
              id="guardianName"
              name="guardianName"
              variant="standard"
              placeholder="보호자 이름을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              value={formData.guardianName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {/* Guardian Contact */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">보호자 연락처*</Typography>
            <TextField
              id="guardianContact"
              name="guardianContact"
              variant="standard"
              placeholder="첫 연락을 위해 최종 매칭된 멘토에게 전달될 예정입니다."
              InputProps={{ className: 'p-2' }}
              value={formData.guardianContact}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <PriorityBasedSubjectSelection onChange={handlePrioritySubjectsChange} />
          </Grid>
          {/* Available Courses */}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">희망 수업 과정*</Typography>
            <Typography className="text-left !text-sm pb-4" color="error">
              다중 선택이 가능합니다.
            </Typography>
            <Box className="flex flex-wrap">
              {COURSE_LIST.map((course) => (
                <BasicCheckbox
                  key={course}
                  name="availableCourses"
                  label={course}
                  data={formData.availableCourses}
                  actionChange={() => handleCheckboxChange(course, 'availableCourses')}
                />
              ))}
            </Box>
          </Grid>
          {/* Local Language */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">
              현지에서 주로 사용하는 언어*
            </Typography>
            <TextField
              id="localLanguage"
              name="localLanguage"
              variant="standard"
              placeholder="현지에서 주로 사용하는 언어를 입력해주세요."
              InputProps={{ className: 'p-2' }}
              value={formData.localLanguage}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {/* Korean Proficiency */}
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">한국어 수준*</Typography>
            <FormControl fullWidth size="small">
              <InputLabel id="korean-proficiency-label">한국어 수준을 선택해주세요.</InputLabel>
              <Select
                labelId="korean-proficiency-label"
                id="koreanProficiency"
                name="koreanProficiency"
                value={formData.koreanProficiency}
                onChange={handleInputChange}
                label="한국어 수준을 선택해주세요."
              >
                <MenuItem value="">
                  <em>선택해주세요.</em>
                </MenuItem>
                {KOREAN_PROFICIENCY_LEVELS.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Available Time */}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">수업 가능 시간대*</Typography>
            <Typography className="text-left !text-sm pb-4" color="error">
              한국 시간을 기준으로 선택해주세요. 가능한 많은 시간을 선택해주셔야 원하는 멘티와
              매칭될 확률이 높아집니다.
            </Typography>
            <Box className="relative">
              {DAYS.map((day) => (
                <Box key={day} className="flex items-center gap-2 py-1">
                  <SquareCheckbox
                    name="availableDay"
                    label={day}
                    data={formData.availableDay}
                    actionChange={() => handleCheckboxChange(day, 'availableDay')}
                  />
                  <Box className="flex overflow-auto">
                    {CLASS_TIMES.map((time) => (
                      <TimeCheckbox
                        key={`${day}-${time}`}
                        name="availableTime"
                        label={time}
                        dataKey={day}
                        data={formData.availableTime}
                        actionChange={() => handleCheckboxChange(`${day}-${time}`, 'availableTime')}
                      />
                    ))}
                  </Box>
                  <div className="absolute right-0 w-8 h-11 bg-gradient-to-l from-blue-50"></div>
                </Box>
              ))}
            </Box>
          </Grid>
          {/* Photo and Prayer Requests */}
          <Grid item xs={12}>
            <Box
              className="flex justify-center items-center px-16 py-10 w-full bg-[#E1F5F8]"
              sx={{ border: '2px dashed #169DC5' }}
            >
              <Typography className="text-left" fontWeight="regular">
                멘티의 사진과 기도제목을 작성해주세요!
                <br />
                매칭될 멘토와 배남프 기획팀이 함께,
                <br />
                하나님 나라를 함께 세워갈 멘티를 위해 계속해서 기도하겠습니다.
                <br />
                <br />
                *사진과 기도제목은 멘토와 기획팀에게만 공유됩니다!
              </Typography>
            </Box>
          </Grid>
          {/* Photo Upload */}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">멘티(학생) 사진*</Typography>
            <Typography className="text-left !text-sm pb-4" color="error">
              사진은 2:3(가로:세로) 비율을 맞춰주시면 가장 좋습니다 :)
            </Typography>
            <Box className="flex items-center justify-center w-full">
              <label
                htmlFor="photo-upload"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
              >
                <Box className="flex flex-col items-center justify-center pt-5 pb-6 gap-4">
                  <AddPhotoAlternateOutlinedIcon
                    sx={{
                      fontSize: '3rem',
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                  <Box
                    className="flex justify-center items-center py-1/2 px-4 rounded-full border-2 border-[#169DC5]"
                    sx={{
                      fontSize: '0.8rem',
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    사진 선택하기
                  </Box>
                  <Typography>{selectedImageName}</Typography>
                </Box>
                <input
                  id="photo-upload"
                  accept="image/png, image/jpeg"
                  type="file"
                  className="hidden"
                  onChange={handleFileSelect}
                />
              </label>
            </Box>
          </Grid>
          {/* Prayer Requests */}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">기도제목*</Typography>
            {['pray1', 'pray2', 'pray3'].map((pray) => (
              <Box key={pray} mb={2}>
                <TextField
                  id={pray}
                  name={pray}
                  variant="outlined"
                  placeholder="30자 이내로 작성해주세요."
                  InputProps={{ className: 'p-2' }}
                  value={formData[pray]}
                  onChange={handlePrayRequestChange}
                  fullWidth
                />
                <Typography className="text-right !text-sm">
                  {formData[pray].length} / 30자
                </Typography>
              </Box>
            ))}
          </Grid>

          {/* **추가된 요구사항 필드** */}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">추가 요구사항</Typography>
            <TextField
              id="additionalRequirements"
              name="additionalRequirements"
              variant="outlined"
              placeholder="추가로 요청하고 싶은 사항이 있으면 작성해주세요."
              InputProps={{ className: 'p-2' }}
              value={formData.additionalRequirements}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* **추가된 요구사항 필드 끝** */}

          {/* Pledge */}
          <Grid item xs={12}>
            <Box
              className="flex flex-col gap-7 justify-center items-center px-16 py-10 w-full bg-[#FFDCB8]"
              sx={{ border: '2px dashed #E4782A' }}
            >
              <Typography className="text-center" variant="h1">
                교재 사용에 관한 저작권 안내
              </Typography>
              <Typography className="text-left" fontWeight="regular">
                아래와 같은 행위를 할 경우 법적인 문제가 발생할 수 있음을 알려드립니다.
                <br />
                <br />
                • 배남프 수업 이외의 목적으로 사용하는 경우
                <br />
                • 개인의 영리를 위해 사용하는 경우
                <br />
                • 타인과 공유하는 경우
                <br />
              </Typography>
              <Box className="flex items-center">
                <Checkbox checked={formData.pledge} onChange={handlePledgeChange} color="primary" />
                <Typography variant="body3">위 내용을 확인했습니다 (필수)</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* Buttons */}
        <Box className="flex flex-row items-center justify-center pt-24">
          <Button
            fullWidth
            className="formButton"
            variant="contained"
            color="inherit"
            onClick={actionPrevStep}
          >
            취소
          </Button>
          <Button fullWidth className="formButton" type="submit" variant="contained">
            완료하기
          </Button>
        </Box>
      </Box>
      {/* Loading Backdrop */}
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Box className="flex justify-center items-center p-8 bg-white rounded-lg">
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>
    </Box>
  );
};

export default MenteeInfo;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography, // 추가
  Box, // 추가
} from '@mui/material';
import { Delete } from '@mui/icons-material'; // Edit 아이콘 삭제
import { selectMatched, deleteMatched } from 'store/matchedSlice';
import { fetchApplicationsByAids, selectApplicationData } from 'store/applicationSlice';
import { doc, updateDoc, arrayRemove, increment } from 'firebase/firestore';
import { db } from 'firebase_config';

const MatchedList = () => {
  const dispatch = useDispatch();
  const matcheds = useSelector(selectMatched);
  const applications = useSelector(selectApplicationData);

  useEffect(() => {
    const aids = new Set();
    matcheds?.forEach((matched) => {
      aids.add(matched.menteeAid);
      aids.add(matched.mentorAid);
    });

    if (aids.size > 0) {
      dispatch(fetchApplicationsByAids(Array.from(aids)));
    }
  }, [dispatch, matcheds]);

  // Function to handle the deletion of a matched record and update applications
  const handleDelete = async (id, matched) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        // Update mentor application
        if (matched.mentorAid) {
          const mentorRef = doc(db, 'Applications', matched.mentorAid);
          await updateDoc(mentorRef, {
            tmpMatchedStatus: increment(-1),
            tmpMatchedSubjects: arrayRemove(matched.subject),
          });
        }

        // Update mentee application
        if (matched.menteeAid) {
          const menteeRef = doc(db, 'Applications', matched.menteeAid);
          await updateDoc(menteeRef, {
            tmpMatchedStatus: increment(-1),
            tmpMatchedSubjects: arrayRemove(matched.subject),
          });
        }

        // Delete matched record
        dispatch(deleteMatched(id));
        alert('Matched record deleted successfully and applications updated.');
      } catch (error) {
        console.error('Error deleting matched record or updating applications:', error);
        alert('Failed to delete matched record or update applications.');
      }
    }
  };

  // Matched 항목 개수
  const matchedCount = matcheds?.length || 0;

  return (
    <>
      {/* Matched 항목 개수 표시 */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Matched List</Typography>
        <Typography variant="body1">매칭 완료된 항목 수: {matchedCount}</Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>mid</TableCell>
              <TableCell>Mentor Name</TableCell>
              <TableCell>Mentee Name</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Generation</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matcheds?.map((matched) => {
              const mentorApplication = applications[matched.mentorAid];
              const menteeApplication = applications[matched.menteeAid];

              return (
                <TableRow key={matched.docId}>
                  <TableCell>{matched?.mid || 'Loading...'}</TableCell>
                  <TableCell>{mentorApplication?.name || 'Loading...'}</TableCell>
                  <TableCell>{menteeApplication?.name || 'Loading...'}</TableCell>
                  <TableCell>{matched.subject}</TableCell>
                  <TableCell>{matched.generation}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(matched.docId, matched)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MatchedList;

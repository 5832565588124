import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  ListItemText,
  Button as MuiButton, // MUI Button을 사용하기 위해 이름 변경
} from '@mui/material';
import InfoBox from 'pages/mypage/components/InfoBox';
import styled from 'styled-components';
import withRouter from 'utils/withRouter';
import { BELONG_LIST, COURSE_LIST } from 'constants/index';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const MentorMatching = (props) => {
  const { application, isLoading, matchingStatus } = props;

  const [subject, setSubject] = useState(''); // 다중 선택에서 단일 선택으로 변경
  const [belong, setBelong] = useState([]);
  const [course, setCourse] = useState([]);

  const { currentGeneration } = fetchRemoteConfigValues();

  // Handle change for Subject with single selection
  const handleChangeSubject = (event) => {
    const { value } = event.target;
    setSubject(value);
  };

  // Handle change for Belong with "Select All" logic
  const handleChangeBelong = (event) => {
    const { value } = event.target;
    if (value.includes('전체')) {
      // Toggle between selecting all and none
      setBelong(belong.length === BELONG_LIST.length ? [] : BELONG_LIST);
    } else {
      setBelong(value);
    }
  };

  // Handle change for Course with "Select All" logic
  const handleChangeCourse = (event) => {
    const { value } = event.target;
    if (value.includes('전체')) {
      // Toggle between selecting all and none
      setCourse(course.length === COURSE_LIST.length ? [] : COURSE_LIST);
    } else {
      setCourse(value);
    }
  };

  // Check if all selections are empty
  const isButtonDisabled =
    matchingStatus !== 0 ||
    (!subject && belong.length === 0 && course.length === 0); // subject.length 변경

  if (isLoading) {
    return (
      <Box className="h-96 flex justify-center items-center">
        <img src="/images/loading.gif" alt="loading..." width={100} />
      </Box>
    );
  }

  return (
    <InfoBox title="매칭하기">
      <Box>
        <Box>
          <Typography align="center">
            📌
            <br />
            배남프 {currentGeneration}기에서는
            <br />
            멘토 한 분이 한 명의 멘티와 만나 하나의 멘토링을 진행하게 됩니다!
            <br />
            신청했던 과목 중 하나를 결정하셔서 매칭 신청하기 버튼을 눌러주세요 :)
          </Typography>
        </Box>
        <Box className="pt-4 flex flex-row flex-wrap justify-center items-center">
          {/* Subject Select */}
          <FormControl sx={{ minWidth: 180, mr: 2, mb: 2 }}>
            <InputLabel id="subjects-label" color="error">
              과목
            </InputLabel>
            <Select
              labelId="subjects-label"
              id="subjects"
              value={subject}
              onChange={handleChangeSubject}
              renderValue={(selected) => selected}
              label="과목"
              color="error"
              disabled={matchingStatus !== 0}
            >
              {application?.availableSubjects
                // .filter((s) => s !== '기타')
                .map((subjectOption, index) => (
                  <MenuItem key={index} value={subjectOption}>
                    <Checkbox checked={subject === subjectOption} />
                    <ListItemText primary={subjectOption} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* Course Select */}
          <FormControl sx={{ minWidth: 180, mr: 2, mb: 2 }}>
            <InputLabel id="courses-label" color="error">
              희망 수업 과정
            </InputLabel>
            <Select
              labelId="courses-label"
              id="courses"
              multiple
              value={course}
              onChange={handleChangeCourse}
              renderValue={(selected) => selected.join(', ')}
              label="희망 수업 과정"
              color="error"
              disabled={matchingStatus !== 0}
            >
              <MenuItem value="전체">
                <Checkbox checked={course.length === COURSE_LIST.length} />
                <ListItemText primary="전체" />
              </MenuItem>
              {COURSE_LIST.map((courseOption, index) => (
                <MenuItem key={index} value={courseOption}>
                  <Checkbox checked={course.indexOf(courseOption) > -1} />
                  <ListItemText primary={courseOption} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Belong Select */}
          <FormControl sx={{ minWidth: 180, mr: 2, mb: 2 }}>
            <InputLabel id="belong-label" color="error">
              섹션
            </InputLabel>
            <Select
              labelId="belong-label"
              id="belong"
              multiple
              value={belong}
              onChange={handleChangeBelong}
              renderValue={(selected) => selected.join(', ')}
              label="섹션"
              color="error"
              disabled={matchingStatus !== 0}
            >
              <MenuItem value="전체">
                <Checkbox checked={belong.length === BELONG_LIST.length} />
                <ListItemText primary="전체" />
              </MenuItem>
              {BELONG_LIST.map((belongOption, index) => (
                <MenuItem key={index} value={belongOption}>
                  <Checkbox checked={belong.indexOf(belongOption) > -1} />
                  <ListItemText primary={belongOption} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* Submit Button */}
        <Box className="flex justify-center mt-2">
          <MuiButton
            onClick={() => {
              if (!subject || belong.length === 0 || course.length === 0) {
                alert('필터를 모두 선택해주세요.');
              } else {
                props.navigate('/matching/mentor', {
                  state: { subject, course, belong },
                });
              }
            }}
            disabled={isButtonDisabled}
            sx={{
              height: '56px',
              padding: '0 24px',
              backgroundColor: '#e4782a',
              borderRadius: '12px',
              color: 'white',
              marginLeft: '16px',
              fontSize: '16px',
              fontWeight: 600,
              transition: 'background-color 0.3s ease, transform 0.2s ease',
              '&:hover': {
                backgroundColor: '#d66b1a',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                backgroundColor: '#c55a0f',
                transform: 'translateY(0)',
              },
              '&:disabled': {
                backgroundColor: '#ccc',
                color: '#666',
                cursor: 'not-allowed',
              },
            }}
          >
            {matchingStatus !== 0 ? '신청완료' : '멘티 선택하기'}
          </MuiButton>
        </Box>
        {matchingStatus !== 0 ? (
          <Box className="flex w-full justify-center py-4">
            <Typography align="center" variant="body3" color={(theme) => theme.palette.error.main}>
              배남프 본부를 통해 매칭이 승인되면 매칭된 멘티 상세 정보가 보여집니다
            </Typography>
          </Box>
        ) : null}
      </Box>
    </InfoBox>
  );
};

// Styled Button 제거하고 MUI Button 사용
const StyledButton = styled(MuiButton)`
    height: 56px;
    padding: 0 24px;
    background-color: #e4782a;
    border-radius: 8px;
    color: white;
    margin-left: 16px;
    border: none;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
    }
`;

export default withRouter(MentorMatching);

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { grey, lightBlue } from '@mui/material/colors';
import Root from 'components/Root';
import { limit, orderBy, where } from 'firebase/firestore';
import applicationConverter from 'models/application_model';
import matchingConverter from 'models/matching_model';
import React, { useEffect, useState } from 'react';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture, setDocument } from 'services/firebase_services';
import styled from 'styled-components';

const ApproveMatching = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [menteeList, setMenteeList] = useState([]);
  const [matchingStatus, setMatchingStatus] = useState({});
  let rowIndex = 0;

  const [statusList, setStatusList] = useState({});

  useEffect(() => {
    handleClick();
  }, [applicationList]);

  // 버튼 클릭 이벤트 핸들러
  const handleClick = () => {
    // statusList 업데이트
    const updatedStatusList = { ...statusList };

    applicationList.forEach((obj) => {
      // 이미 존재하는 userId는 업데이트하지 않음
      if (!updatedStatusList[obj.mid]) {
        updatedStatusList[obj.mid] = '대기';
      }
    });

    // 상태 업데이트
    setStatusList(updatedStatusList);
  };

  // 사용자 상태를 업데이트하는 함수
  const updateUserStatus = (userId, status) => {
    const updatedStatusList = { ...statusList, [userId]: status };
    setStatusList(updatedStatusList);
  };

  // 상태에 따른 배경색을 반환하는 함수
  const getStatusColor = (status) => {
    switch (status) {
      case '승인':
        return '#a4ffa4'; // 초록색
      case '대기':
        return '#ffffff'; // 회색
      case '반려':
        return '#ff9191'; // 빨간색
      default:
        return 'none'; // 기본값은 배경색 없음
    }
  };

  const handleChange = (event, index) => {
    // Array.from({
    //   length: 24,
    //   3: 0,
    //   5: 1,
    //   6: 1,
    //   7: 2,
    //   8: 2,
    //   9: 2,
    // })
    // console.log(event.target.value + '--' + index);
    matchingStatus[index] = event.target.value;
    setMatchingStatus({ ...matchingStatus });
  };

  const columns = [
    { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
    { id: '신청날짜', label: '신청날짜', minWidth: 70, align: 'left' },
    { id: '멘티이름', label: '멘티이름', minWidth: 70, align: 'left' },
    { id: '이메일', label: '이메일', minWidth: 20, align: 'left' },
    {
      id: '과목',
      label: '과목',
      minWidth: 100,
      align: 'left',
    },
    {
      id: '수업코스',
      label: '수업코스',
      minWidth: 100,
      align: 'left',
    },
    { id: '멘토이름', label: '멘토이름', minWidth: 70, align: 'left' },
    { id: '이메일', label: '이메일', minWidth: 20, align: 'left' },
    {
      id: '과목',
      label: '과목',
      minWidth: 100,
      align: 'left',
    },
    {
      id: '수업코스',
      label: '수업코스',
      minWidth: 100,
      align: 'left',
    },
    {
      id: '과목',
      label: '과목',
      minWidth: 40,
      align: 'left',
    },
    {
      id: '후보멘티',
      label: '후보멘티',
      minWidth: 60,
      align: 'left',
    },
    {
      id: '시스템상 매칭 여부',
      label: '시스템상 매칭 여부',
      minWidth: 20,
      align: 'left',
    },
  ];

  /// 불러오기 버튼 클릭 시 멘티 application + 매칭 데이터 를 조합한 object 생성
  async function updateData() {
    await updateMentee();
    await updateApplication();
  }

  async function updateMentee() {
    const docs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => applicationConverter.fromFirestore(data),
      [
        where('userType', '==', 'mentee'),
        // where('matchingStatus', '<', 2),
        // where('availableSubjects', 'array-contains', subject),
        // where('availableCourses', 'array-contains-any', courses),
      ]
    );
    if (docs.length === 0) {
    } else {
      // 테스트 계정 멘티는 지워주기
      const temp = docs.filter(
        (doc) =>
          doc.email !== 'bhgt9510@naver.com' &&
          doc.email !== 'bhgt4563@gmail.com' &&
          doc.email !== 'cwcw0908@gmail.com' &&
          doc.email !== 'ljeongmin0908@gmail.com'
      );
      const result = temp.sort((a, b) => (a.name < b.name ? -1 : 1));
      setMenteeList([...result]);
    }
  }

  async function updateApplication() {
    const docs = await getCollectionFuture(
      FirestorePath.matchings(),
      (data, docId) => matchingConverter.fromFirestore(data),
      // [limit(15)]
      []
    );
    if (docs === undefined) {
      setApplicationList([]);
    } else if (docs.length === 0) {
      setApplicationList([]);
    } else {
      // 테스트 계정 멘티는 지워주기
      const temp = docs.filter(
        (doc) =>
          doc.mentorEmail !== 'bhgt9510@naver.com' &&
          doc.mentorEmail !== 'bhgt4563@gmail.com' &&
          doc.mentorEmail !== 'cwcw0908@gmail.com' &&
          doc.mentorEmail !== 'ljeongmin0908@gmail.com' &&
          doc.mentorName !== '윤한규' &&
          doc.mentorName !== '이정민'
      );
      setApplicationList([...temp]);
    }
  }

  return (
    <Box className="flex flex-col w-full p-10">
      <Box className="flex flex-row gap-8">
        {/* TODO: 버튼 하나로 바로 데이터 불러오도록 작업 */}
        {/* TODO: 불러온 데이터 object 로 합쳐서 표 그려주도록 작업 */}
        {/* <Button onClick={updateData}>데이터 불러오기 | 총 수업 개수 - {0}개 </Button> */}
        <Button onClick={updateMentee}>1. 멘티불러오기 - {menteeList.length}명</Button>
        <Button onClick={updateApplication}>
          2. 매칭데이터불러오기 - {applicationList.length}건
        </Button>
      </Box>
      {menteeList.length ? (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {menteeList.map((mentee, menteeIndex) => {
                  const applications = applicationList.filter((a) =>
                    a.menteeAidList.includes(mentee.aid)
                  );

                  const subjectList = applications.map((mat) => mat.subject);

                  return applications.map((a, index) => {
                    const temp = menteeList.filter((m) => a.menteeAidList.includes(m.aid));
                    const menteeNameList = temp.map((m) => m.name);
                    const subLength = subjectList.filter((s) => s === a.subject).length;

                    // 매칭 후보멘티가 한 명이면 true
                    // if (menteeNameList.length === 1 && a.state === 0) {
                    //   const newList = applicationList.map((matching) => {
                    //     if (matching.mid === a.mid) {
                    //       return {
                    //         ...matching,
                    //         state: 1,
                    //         approvedMentee: temp[0].aid,
                    //       };
                    //     }
                    //     return matching;
                    //   });
                    //   setApplicationList([...newList]);
                    // }

                    // if (
                    //   applications.filter((app) => app.subject === a.subject && app.state === 1)
                    //     .length
                    // ) {
                    //   console.log('a');
                    //   console.log(a);
                    // }

                    const status = a.state === 0 ? 0 : mentee.aid === a.approvedMentee ? 1 : 2;

                    // rowIndex++;

                    const backgroundColor = getStatusColor(statusList[mentee.aid + a.mid]);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={mentee.aid + index}
                        style={{ backgroundColor }} // 드롭다운에도 같은 배경색 적용
                      >
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">
                          {a.appliedAt.toDate().toLocaleDateString('ko-KR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          })}
                        </TableCell>
                        <TableCell align="left">{mentee.name}</TableCell>
                        <TableCell align="left">{mentee.email}</TableCell>
                        <TableCell align="left">{mentee.availableSubjects.join(', ')}</TableCell>
                        <TableCell align="left">{mentee.availableCourses.join(', ')}</TableCell>
                        <TableCell align="left">{a.mentorName}</TableCell>
                        <TableCell align="left">{a.mentorEmail}</TableCell>
                        <TableCell align="left">{a.mentorSubjects.join(', ')}</TableCell>
                        <TableCell align="left">{a.mentorCourses.join(', ')}</TableCell>
                        <TableCell align="left">{a.subject}</TableCell>
                        <TableCell align="left">{menteeNameList.join(', ')}</TableCell>
                        <TableCell align="left">{statusList[mentee.aid + a.mid]}</TableCell>
                        <TableCell align="left">
                          <select
                            value={statusList[mentee.aid + a.mid] || '대기'}
                            onChange={(e) => updateUserStatus(mentee.aid + a.mid, e.target.value)}
                          >
                            <option value="대기">대기</option>
                            <option value="승인">승인</option>
                            <option value="반려">반려</option>
                          </select>
                          {/* <FormControl key={a.mid + index} fullWidth>
                            <Select
                              labelId={a.mid + index + '-lable'}
                              id={a.mid + index}
                              value={
                                matchingStatus[`${menteeIndex}-${index}`] ??
                                (status === 0
                                  ? '대기'
                                  : status === 1
                                  ? '승인'
                                  : status === 2
                                  ? '반려'
                                  : '보류')
                              }
                              label="MatchingStatus"
                              onChange={(event) => handleChange(event, `${menteeIndex}-${index}`)}
                            >
                              <MenuItem value={'대기'}>대기</MenuItem>
                              <MenuItem value={'승인'}>승인</MenuItem>
                              <MenuItem value={'반려'}>반려</MenuItem>
                            </Select>
                          </FormControl> */}
                        </TableCell>
                      </TableRow>
                    );
                  });
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 0px;
`;

const TestButton = styled.button`
  display: flex;
  justify-content: center;
  width: 240px;
  background-color: lightblue;
  padding: 4px;
`;

const Application = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 10px;

  width: 640px;
`;

const ApproveButton = styled.button`
  display: flex;
  justify-content: center;
  width: 80px;
  background-color: ${(isActive) => {
    return isActive.isActive ? 'grey' : 'lightBlue';
  }};
  border-radius: 20px;
  padding: 4px;
`;

export default ApproveMatching;

// tunV5ePNIPMfZtSDbbuX | 최은우 | 1
// L0MZCbqLxg6VKzM5dmoJ | 김아연 멘토 | 1
// KIAXSlszEEDgEG0nLJBb | 권시안 | 1

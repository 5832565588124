export const COMMON = {
  ORDINAL: 6,

  // ~4기
  // TOTAL_MENTOR: 402,
  // TOTAL_MENTEE: 246,

  // ~5기
  TOTAL_MENTOR: 614,
  TOTAL_MENTEE: 466,
  TOTAL_CLASS: 547,
};

// admin 페이지 등에서 쿼리된 결과물에서 admin 계정 지워주기 위해 사용
export const adminAccountList = [
  'bhgt4563@gmail.com',
  'ljeongmin0908@gmail.com',
  'dpwls2588@gmail.com',
  'baenampit@gmail.com',
];

export const SUBJECT_LIST = ['국어', '영어', '수학', '과학', '사회', '역사', '한국어', '기타'];
export const MATCHING_SUBJECT_LIST = [
  '국어',
  '영어',
  '수학',
  '과학',
  '사회',
  '역사',
  '한국어',
  '기타',
];
export const BELONG_LIST = ['해외 거주', '온누리 M 센터', '국내 지역교회', '기타'];
export const EXTRA_BELONG_LIST = {
  '해외 거주': { title: '해외 거주', placeholder: '거주 국가를 입력해주세요.' },
  '온누리 M 센터': { title: '온누리 M 센터', placeholder: '지역을 입력해주세요.' },
  '국내 지역교회': {
    title: '국내 지역교회',
    placeholder: '교회명/담임목사님 성함을 입력해주세요.',
  },
  기타: { title: '기타', placeholder: '소속을 입력해주세요' },
};
export const COURSE_LIST = [
  '유치부',
  '초등-1',
  '초등-2',
  '초등-3',
  '초등-4',
  '초등-5',
  '초등-6',
  '중등-1',
  '중등-2',
  '중등-3',
  '고등-1',
  '고등-2',
  '고등-3',
  '대학이상',
];
export const DAYS = ['월', '화', '수', '목', '금', '토', '일'];
export const CLASS_TIMES = [
  '00-01',
  '01-02',
  '02-03',
  '03-04',
  '04-05',
  '05-06',
  '06-07',
  '07-08',
  '08-09',
  '09-10',
  '10-11',
  '11-12',
  '12-13',
  '13-14',
  '14-15',
  '15-16',
  '16-17',
  '17-18',
  '18-19',
  '19-20',
  '20-21',
  '21-22',
  '22-23',
  '23-24',
];
export const KOREAN_PROFICIENCY_LEVELS = ['초급', '중급', '고급', '원어민 수준'];

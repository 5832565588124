import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture } from 'services/firebase_services';
import * as XLSX from 'xlsx';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const UsersMentors = () => {
  /// false 는 오름차순(asc), true 는 내림차순(desc)
  const [columnSort, setColumnSort] = useState({
    email: false,
    name: false,
    belong: false,
    extraBelong: false,
    gender: false,
    availableSubjects: false,
    availableCourses: false,
    guardianName: false,
    guardianContact: false,
  });
  const [graphData, setGraphData] = useState([]);

  const [matchingStatus, setMatchingStatus] = useState({});
  const [accountNum, setAccountNum] = useState(0);
  let rowIndex = 0;

  useEffect(() => {}, [graphData]);

  const columns = [
    { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
    { id: 'email', label: '이메일', minWidth: 70, align: 'left' },
    { id: 'name', label: '멘토이름', minWidth: 70, align: 'left' },
    { id: 'gender', label: '성별', minWidth: 20, align: 'left' },
    {
      id: 'availableSubjects',
      label: '과목',
      minWidth: 100,
      align: 'left',
    },

    { id: 'extraSubjects', label: '기타과목', minWidth: 70, align: 'left' },
    {
      id: 'availableCourses',
      label: '수업코스',
      minWidth: 100,
      align: 'left',
    },
  ];

  async function updateMentor() {
    const userDocss = await getCollectionFuture(
      FirestorePath.users(),
      (data, docId) => data.data(),
      [where('userType', '==', 'mentor')]
    );

    // 테스트 계정 멘토는 지워주기
    const userDocs = userDocss.filter(
      (doc) => doc.email !== 'ljeongmin0908@gmail.com' && doc.email !== 'dpwls2588@gmail.com'
    );

    setAccountNum(userDocs.length);

    const applicationDocs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => data.data(),
      [where('userType', '==', 'mentor'), where('generation', '==', GENERATION)]
    );

    if (userDocs === undefined) {
      console.log('docs undefined');
      setGraphData([]);
    } else if (userDocs.length === 0) {
      console.log('no data');
      setGraphData([]);
    } else {
      console.log('do something');

      var tempList = [];

      const docs = userDocs.map((mentor) => {
        var app = applicationDocs.filter((application) => application['uid'] === mentor['uid']);

        if (app.length === 0) {
          app = [
            // {
            //   uid: mentor['uid'],
            //   email: mentor['email'],
            //   name: '',
            //   belong: '',
            //   extraBelong: '',
            //   gender: '',
            //   availableSubjects: [''],
            //   availableCourses: [''],
            //   availableDateTime: [''],
            //   prayerRequest: [''],
            //   guardianName: '',
            //   guardianContact: '',
            // },
          ];
        }

        tempList = [...tempList, ...app];
      });

      // 테스트 계정 멘토는 지워주기
      const temp = tempList.filter(
        (doc) =>
          doc['email'] !== 'bhgt4563@gmail.com' &&
          doc['email'] !== 'baenampit@gmail.com' &&
          doc['email'] !== 'eco.yeham@gmail.com' &&
          doc['email'] !== 'baenamproject@gmail.com'
        // doc['email'] !== 'ljeongmin0908@gmail.com' &&
        // doc['email'] !== 'dpwls2588@gmail.com'
      );

      /// < : 오름차순(asc) 정렬, >: 내림차순(desc) 정렬
      const result = temp.sort((a, b) => (a['email'] < b['email'] ? -1 : 1));
      // console.log(result);
      setGraphData([...result]);
    }
  }

  const columnList = [
    'email',
    'name',
    'gender',
    'birth',
    'contact',
    'availableSubjects',
    'availableCourses',
    'availableDateTime',
    'extraSubjects',
  ];

  // 데이터 전처리 함수
  function preprocessData(data) {
    var a = data.map((item) => {
      return {
        이메일: item.email,
        이름: item.name,
        성별: item.gender,
        생일: item.birth,
        연락처: item.contact,
        '수업 가능 과목': item.availableSubjects.toString(),
        수업가능시간: item.availableTime.toString(),
        수업가능과정: item.availableCourses.toString(), // 배열의 모든 항목을 하나의 문자열로 합침
        '기타 과목': item.extraSubjects,
        '선호 멘티 섹터': item.extraBelong?.toString() ?? '',
      };
    });

    return a;
  }

  // Excel 파일로 내보내기 함수
  function exportToExcel(data, columnList) {
    // 데이터 전처리
    const processedData = preprocessData(data);

    // 현재 시간 가져오기 (YYYYMMDD_HHMMSS 형식)
    const now = new Date();
    const fileNameDatePart = `${now.getFullYear()}${(now.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now
      .getHours()
      .toString()
      .padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    // 워크시트 생성
    const worksheet = XLSX.utils.json_to_sheet(processedData);

    // 워크북 생성 및 워크시트 추가
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Excel 파일 생성 (브라우저에서 다운로드)
    XLSX.writeFile(workbook, `mentor_${fileNameDatePart}.xlsx`);
  }

  const createSortHandler = (property) => (event) => {
    const ids = [
      'no.',
      'email',
      'name',
      'belong',
      'extraBelong',
      'gender',
      'availableSubjects',
      'availableCourses',
      'guardianName',
      'guardianContact',
    ];

    if (ids.includes(event.target.id) && event.target.id != 'no.') {
      const id = event.target.id;

      /// < : 오름차순(asc) 정렬, >: 내림차순(desc) 정렬
      const result = graphData.sort((a, b) =>
        columnSort[id] ? (a[id] > b[id] ? -1 : 1) : a[id] < b[id] ? -1 : 1
      );

      setColumnSort({ ...columnSort, [id]: !columnSort[id] });
      setGraphData([...result]);
    }
  };

  return (
    <Box className="flex flex-col gap-8 mb-8">
      <Box className="flex flex-row gap-8 mb-8">
        <Button variant="contained" onClick={() => updateMentor()}>
          불러오기
        </Button>
        <Button variant="contained" onClick={() => exportToExcel(graphData, columnList)}>
          excel export
        </Button>
      </Box>

      {graphData.length ? (
        <Box className="flex flex-col gap-4">
          <Box className="flex gap-4 pb-4">
            <Box>총 {graphData.length}개</Box>
          </Box>
          <Paper elevation={10} sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ height: '80vh' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          id={column.id}
                          hideSortIcon={true}
                          active={column.id != 'no.' ? true : false}
                          direction={columnSort[column.id] ? 'asc' : 'desc'}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {graphData.map((data, dataIndex) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={data.uid + dataIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{data.email}</TableCell>
                        <TableCell align="left">{data.name}</TableCell>
                        <TableCell align="left">{data.gender}</TableCell>
                        <TableCell align="left">{data.availableSubjects.join(', ')}</TableCell>
                        <TableCell align="left">{data.extraSubjects}</TableCell>
                        <TableCell align="left">{data.availableCourses.join(', ')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default UsersMentors;

import { Box, Divider, Grid, Typography } from '@mui/material';
import { where } from 'firebase/firestore';
import applicationConverter from 'models/application_model';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture } from 'services/firebase_services';
import { selectUser } from 'store/userSlice';

const Info = (props) => {
  const user = useSelector(selectUser);

  const { userType } = user;
  const { contactVisible, partner } = props;

  // 화면을 그릴때 사용할 application
  const [application, setApplication] = useState({});

  // application 업데이트 해주는 함수
  useEffect(() => {
    async function getApplication() {
      // Firestore에서 멘토 신청서 가져오기
      const docs = await getCollectionFuture(
        FirestorePath.applications(),
        (data, docId) => applicationConverter.fromFirestore(data),
        [where('aid', '==', partner.aid)]
      );

      const { name, gender, birth, email, contact, availableCourses, belong, guardianContact } =
        docs[0];

      const courseList = availableCourses;

      const today = new Date();
      const birthDate = new Date(birth);
      const age = today.getFullYear() - birthDate.getFullYear();

      const lastName = name.replaceAll(' ', '').substring(0, 1);
      const firstName = name.replaceAll(' ', '').substring(1);
      const profileName = firstName.length > 4 ? lastName : firstName;

      const menteeSector = userType === 'mentor' ? `${belong}` : '';
      const menteeGuardianContact = userType === 'mentor' ? `${guardianContact}` : '';

      setApplication({
        profileName: profileName,
        name: name,
        gender: gender,
        birth: birth,
        age: age,
        email: email,
        contact: contact,
        menteeSector: menteeSector,
        guardianContact: menteeGuardianContact,
        courseList: courseList,
      });
    }

    getApplication();
  }, [partner.aid]);

  return (
    <Box
      className="flex sm:flex-row flex-col rounded-2xl group/myInfo gap-8 p-5"
      sx={{ backgroundColor: '#F5F5F5' }}
    >
      <Box className="flex items-center sm:px-4 px-0">
        <Box
          className="flex justify-center items-center w-28 h-28 rounded-full"
          sx={{ backgroundColor: '#' + Math.round(Math.random() * 0xffffff).toString(16) }}
        >
          <Typography fontSize="1.75rem" color="white">
            {application.profileName}
          </Typography>
        </Box>
      </Box>
      <Box className="flex flex-1 flex-col">
        <Box className="flex-1 flex-col justify-between">
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  이름(성별)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Box className="flex gap-1">
                  <Typography variant="body3">{application.name}</Typography>
                  <Typography
                    variant="body3"
                    fontWeight="bold"
                    color={(theme) =>
                      application.gender === 'M'
                        ? theme.palette.primary.main
                        : theme.palette.error.main
                    }
                  >
                    {application.gender === 'M' ? '남' : '여'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  생년월일(나이)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body3">{`${application.birth?.replaceAll('-', '.') ?? ''}(${
                  application.age
                }세)`}</Typography>
              </Grid>
            </Grid>
          </Box>
          {contactVisible ? (
            <>
              <Box className="flex">
                <Grid container wrap="nowrap" spacing={0}>
                  <Grid item xs={5}>
                    <Typography variant="body3" fontWeight="bold">
                      이메일
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body3" sx={{ overflowWrap: 'break-word' }}>
                      {application.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className="flex">
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <Typography variant="body3" fontWeight="bold">
                      연락처
                      {/* {userType === 'mentee' ? '(카카오ID)' : ''} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body3">
                      {/* {userType === 'mentor' ? '@' : ''} */}
                      {application.contact}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {userType === 'mentor' ? (
                <>
                  <Box className="flex">
                    <Grid container spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="body3" fontWeight="bold">
                          멘티섹터
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body3">{application.menteeSector}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="flex">
                    <Grid container spacing={0}>
                      <Grid item xs={5}>
                        <Typography variant="body3" fontWeight="bold">
                          보호자 연락처
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body3">{application.guardianContact}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          ) : null}
        </Box>
        <Box className="py-4">
          <Divider />
        </Box>
        <Box className="flex-1 flex-col">
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid className="flex flex-wrap gap-1 justify-start" item xs={12}>
                {application.courseList?.map((course, index) => (
                  <Typography
                    key={index}
                    className="border rounded-full px-2"
                    variant="body3"
                    sx={{ borderColor: (theme) => theme.palette.figma.gray02 }}
                  >
                    {course}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Info;
